var getUrl = window.location;
var apiLink = getUrl.protocol + "//" + getUrl.host + "/cms";
if (process.env.REACT_APP_DEBUG === "true") {
    apiLink = "http://127.0.0.1:81/cms"
}

export async function fetchData(url, params) {
    return fetch( apiLink + url, params )
    .then(response => response.json())
    .then(data => { return data })
    .catch(_err => { return "ERROR" })
}
