import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Table, Image, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { getCategories } from '../../api/fetch'

import './categories.css'
import { AddCategory } from './forms/AddCategory'
import { EditCategory } from './forms/EditCategory'


export const Categories = (props) => {

    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(false)
    const [showAddForm, setShowAddForm] = useState(false)
    const [showEditForm, setShowEditForm] = useState(false)
    const [categorySelected, setCategorySelected] = useState({})
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    useEffect(() => {
        if (props.parent.id && !loading) {
            fetchCategories(props.parent.id.split('-')[1])
        }
    }, [props.parent])

    const scrollToForm = () => {
        window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    }
    const handleAddCategory = () => {
        setShowEditForm(false)
        setShowAddForm(true)

        setTimeout(function () {
            scrollToForm()
        }, 200);
    }

    const handleDestroyForms = () => {
        setShowAddForm(false)
        setShowEditForm(false)
        if (!loading) fetchCategories(props.parent.id.split('-')[1])
    }

    const fetchCategories = (async (id) => {
        setLoading(true)
        setCategories([])
        const method = 'GET'
        const result = await getCategories({ method, id });
        if (result) setCategories(result)
        setLoading(false)
    })
    const editCategory = (category) => {
        setShowAddForm(false)
        setCategorySelected(category)
        setShowEditForm(true)

        setTimeout(function () {
            scrollToForm()
        }, 200);
    }

    // const handleDelete = (label) => {
    //     setLabelToDelete(label)
    //     setShowDeleteModal(true)
    // }

    return (
        <Container>
            <Row className="title">
                <Col>
                    <h2>{props.parent.name + " > Categories"}</h2>
                </Col>
            </Row>
            {loading ? <div>Loading...</div> :
                <div id="labels-table" className="scrollable">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Image</th>
                                <th>Cover Image</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <>
                                {categories.map(category => (
                                    <tr key={category.id}>
                                        <td>{category.name}</td>
                                        <td><Image src={category.image} thumbnail width="70" height="80"></Image></td>
                                        <td><Image src={category.cover_image} thumbnail width="70" height="80"></Image></td>
                                        <td><FontAwesomeIcon className="c-black me-2 button" icon={faEdit} onClick={() => editCategory(category)} /></td>
                                    </tr>
                                ))}
                            </>
                        </tbody>
                    </Table>
                </div>
            }
            {categories.length === 0 ? <Row className="no-label-text"><Col className="text-center font-size-20">No Categories</Col></Row> : <div />}
            <Button className="bg-dark-blue mt-2" variant="primary" type="button" onClick={() => handleAddCategory()}>
                Add Category
            </Button>
            {showAddForm ? <AddCategory parent={props.parent} destroyForm={handleDestroyForms} /> : <div />}
            {showEditForm ? <EditCategory parent={props.parent} elementSelected={categorySelected} destroyForm={handleDestroyForms} /> : <div />}
        </Container>
    )
}