import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap'
import { categoryFetch, displayFetch, foodGroupFetch } from '../../../api/fetch'
import { CheckboxInput } from './commons/CheckboxInput';
import { NumberInput } from './commons/NumberInput';
import { SvgUploader } from './commons/SvgUploader';
import { TextInput } from './commons/TextInput'
import { TooltipComponent } from "./commons/TooltipComponent";
import { Uploader } from "./commons/Uploader";

export const EditCategory = (props) => {

    const [newCategoryName, setNewCategoryName] = useState("")
    const [categoryImage, setCategoryImage] = useState("")
    const [categoryOrder, setCategoryOrder] = useState(0)
    const [categoryCoverImage, setCategoryCoverImage] = useState("")
    const [isFormError, setIsFormError] = useState(false)

    useEffect(() => {
        setNewCategoryName(props.elementSelected.name)
        setCategoryOrder(props.elementSelected.order)
        setCategoryImage(props.elementSelected.image)
        setCategoryCoverImage(props.elementSelected.cover_image)
    }, [props.elementSelected])

    const handleSubmit = () => {

        if (!(newCategoryName && categoryImage && categoryCoverImage)) {
            setIsFormError(true)
            return
        }

        setIsFormError(false)

        let theImage = categoryImage
        let theCoverImage = categoryCoverImage

        if (theImage === "") {
            theImage = props.elementSelected.image
        }
        if (theCoverImage === "") {
            theCoverImage = props.elementSelected.cover_image
        }

        let category = {
            id: props.elementSelected.id,
            name: newCategoryName,
            order: parseInt(categoryOrder),
            cover_image: theCoverImage,
            image: theImage,
            kind: "CATEGORYFOODGROUP",
            place_id: parseInt(props.parent.id.split('-')[1]),
        }

        postCategory(category)
    }

    const postCategory = (async (category) => {
        const method = "PUT"
        const body = JSON.stringify(category)
        const headers = {
            'Content-Type': 'application/json',
        }
        await categoryFetch({ method, body, headers }).then(() => { props.destroyForm() });
    })

    return (
        <Form className="pb-5">
            <>
                <Row className="pt-4">
                    <Col xs={6} md={6}>
                        <Form.Label>*Category name</Form.Label>
                        <TextInput value={newCategoryName} setValue={setNewCategoryName} />
                    </Col>
                    <Col xs={2} md={2}>
                        <Form.Label>*Cateogry order</Form.Label>
                        <NumberInput value={categoryOrder} setValue={setCategoryOrder} />
                    </Col>
                </Row>
                <Row className="pt-3">
                    <Col xs={6} md={6}>
                        <div className="d-flex align-items-baseline">
                            <Form.Label>*Category cover_image</Form.Label>
                            <TooltipComponent />
                        </div>
                        <Uploader value={categoryCoverImage} setValue={setCategoryCoverImage} />
                    </Col>
                    <Col xs={6} md={6}>
                        <div className="d-flex align-items-baseline">
                            <Form.Label>*Category image</Form.Label>
                            <TooltipComponent />
                        </div>
                        <Uploader value={categoryImage} setValue={setCategoryImage} />
                    </Col>
                </Row>
            </>
            <Row className="justify-content-end submit-button" >
                <Col xs={2} className="text-end">
                    <Button className="bg-dark-blue" onClick={() => handleSubmit()}>Save</Button>
                </Col>
            </Row>
            {isFormError ? <Row className="error-message">Fill the inputs marked with *</Row> : <div />}
        </Form>
    )

}