import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

import './success.css'

export const Success = () => {

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col xs={12} className="text-center">
                    <h1>Operation completed </h1>
                </Col>
            </Row>
            <Row className="justify-content-md-center check">
                <Col xs={6} className="text-center">
                <FontAwesomeIcon className="c-green fa-9x" icon={faCheck} />
                </Col>
            </Row>
        </Container>
    )
}