import React, { useState } from 'react';
import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import { Redirect } from "react-router-dom";

import './login.css'

export const Login = () => {

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [showInvalidCredentialError, setShowInvalidCredentialsError] = useState(false)
    const [validLogin, setValidLogin] = useState(false)

    const handleSubmit = () => {
        setShowInvalidCredentialsError(false)
        if (username === process.env.REACT_APP_USERNAME && password === process.env.REACT_APP_PASSWORD) {
            localStorage.setItem("loggedIn", true)
            setValidLogin(true)
        }
        else {
            setShowInvalidCredentialsError(true)
        }
    }

    return (
        <Container className="login">
            {localStorage.getItem("loggedIn") ? <Redirect to="/dashboard" /> :
                <Form>
                    <h2 className="text-center pb-2">Login</h2>
                    <div className="border border-dark rounded">
                        <Row className="pt-4 m-auto">
                            <Col xs={12}>
                                <Form.Control type="text" value={username} onChange={(e) => { setUsername(e.target.value) }} placeholder="username" />
                            </Col>
                        </Row>
                        <Row className="pt-4 m-auto">
                            <Col xs={12}>
                                <Form.Control type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} placeholder="password" />
                            </Col>
                        </Row>
                        <Row className="justify-content-center pt-3 pb-4" >
                            <Col xs={4} className="text-center">
                                <Button className="bg-dark-blue" onClick={() => handleSubmit()}>Login</Button>
                            </Col>
                        </Row>
                    </div>
                    {showInvalidCredentialError ? <Row className="error-message text-center justify-content-center m-auto pt-1">Invalid credentials</Row> : <div />}
                </Form>
            }
        </Container>
    )
}