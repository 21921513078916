import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import './alertModals.css'


export const AlertModal = (props) => {
  
    const handleClose = () => props.setShow(false);
    
    const onPrimaryClick = () => {
        props.primaryAction(props.element)
        props.setShow(false)
    }

    return (
      <>  
        <Modal show={props.show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Caution</Modal.Title>
          </Modal.Header>
          <Modal.Body>{props.message}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" className="bg-dark-blue" onClick={onPrimaryClick}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }