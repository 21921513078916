import React from 'react';
import { Form } from 'react-bootstrap';

export const NumberInput = (props) => {

    const handleValueChange = (e) => {
        props.setValue(e.target.value)
    }

    return (
        <Form.Control type="number" value={props.value} onChange={(e) => handleValueChange(e)} disabled={props.disabled} />
    )
}