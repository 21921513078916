import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Dashboard from './components/dashboard/Dashboard'
import { TemplateReader } from "./components/readers/TemplateReader";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Login } from "./components/login/Login";

function App() {
  return (
    <Router>
      <Switch>
      <Route path="/template">
          <TemplateReader />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        <Route path="/">
          <Login />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
