import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { displayFetch, getDisplays, getFoodGroups } from '../../api/fetch';
import { AlertModal } from './forms/commons/AlertModal'
import { AddDisplay } from './forms/AddDisplay';
import { AddImago } from './forms/AddImago';
import { EditDisplay } from './forms/EditDisplay';
import { EditImago } from './forms/EditImago';

import './display.css'

export const Displays = (props) => {

    const [displays, setDisplays] = useState([])
    const [loading, setLoading] = useState(false)
    const [displaytoDelete, setDisplayToDelete] = useState({})
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showAddDisplayForm, setShowAddDisplayForm] = useState(false)
    const [showEditDisplayForm, setShowEditDisplayForm] = useState(false)
    const [displaySelected, setDisplaySelected] = useState({})
    const [formKind, setFormKind] = useState("DISPLAYEDITOR")
    const [foodGroups, setFoodGroups] = useState([])

    useEffect(() => {
        //handleDestroyForm()
        if (props.parent.id && !loading) {
            fetchDisplays(props.parent.id.split('-')[1]).then(() => fetchFoodGroups(props.parent.id.split('-')[1]))
        }
    }, [props.parent, displaySelected])

    const fetchDisplays = (async (id) => {
        setLoading(true)
        setDisplays([])
        const method = 'GET'
        const result = await getDisplays({ method, id });
        if (result) setDisplays(result)
        setLoading(false)
    })
    const fetchFoodGroups = (async (id) => {
        setLoading(true)
        setFoodGroups([])
        const method = 'GET'
        const result = await getFoodGroups({ method, id });
        if (result) setFoodGroups(result)
        setLoading(false)

    })
    const deleteDisplay = (async () => {
        setLoading(true)
        const method = "DELETE"
        const body = JSON.stringify(displaytoDelete)
        const headers = {
            'Content-Type': 'application/json',
        }
        await displayFetch({ method, body, headers });
        fetchDisplays(props.parent.id.split('-')[1])
    })

    const handleDelete = (display) => {
        setDisplayToDelete(display)
        setShowDeleteModal(true)
    }
    const handleAddDisplay = () => {
        setShowEditDisplayForm(false)
        setShowAddDisplayForm(true)
    }

    const handleDestroyForm = () => {
        setShowAddDisplayForm(false)
        setShowEditDisplayForm(false)
        fetchDisplays(props.parent.id.split('-')[1]).then(() => fetchFoodGroups(props.parent.id.split('-')[1]))
    }

    const handleEdit = (display) => {
        setShowAddDisplayForm(false)
        setFormKind(display.display.kind)
        setDisplaySelected(display)
        setShowEditDisplayForm(true)
    }

    return (
        <Container>
            <Row>
                <Col>
                    <AlertModal message="Are you sure you want to delete?" primaryAction={deleteDisplay} show={showDeleteModal} setShow={setShowDeleteModal} element={displaytoDelete} />
                </Col>
            </Row>
            <Row className="title pb-2">
                <Col>
                    <h2>{props.parent.name + " > Displays"}</h2>
                </Col>
            </Row>
            {loading ? <div>Loading...</div> :
                <div id="display-table" className="scrollable">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Kind</th>
                                <th>Tablet ID</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <>
                                {displays.map(display => (
                                    <tr key={display.display.id}>
                                        <td>{display.display.id}</td>
                                        <td>{display.display.name}</td>
                                        <td>{display.display.kind}</td>
                                        {display.display.kind === "IMAGOTAG" ? <td>{display.display.imagotag_config.devices[0].tablet_id}</td> : <td></td>}
                                        <td><FontAwesomeIcon className="c-black me-2 button" icon={faEdit} onClick={() => handleEdit(display)} /></td>
                                        <td><FontAwesomeIcon className="c-black me-2 button" icon={faTrash} onClick={() => handleDelete(display)} /></td>
                                    </tr>
                                ))}
                            </>
                        </tbody>
                    </Table>
                </div>
            }
            {displays.length === 0 ? <Row className="no-label-text"><Col className="text-center font-size-20">No displays</Col></Row> : <div />}
            <Button className="bg-dark-blue mt-2" variant="primary" type="button" onClick={() => handleAddDisplay()}>
                Add Display
            </Button>
            {showAddDisplayForm ?
                <div>
                    {formKind === "DISPLAYEDITOR" ? <AddDisplay parent={props.parent} destroyForm={handleDestroyForm} formKind={formKind} setFormKind={setFormKind} /> : <AddImago parent={props.parent} destroyForm={handleDestroyForm} formKind={formKind} setFormKind={setFormKind} foodGroups={foodGroups} />}
                </div>
                : <div />}
            {showEditDisplayForm ?
                <div>
                    {formKind === "DISPLAYEDITOR" ? <EditDisplay parent={props.parent} destroyForm={handleDestroyForm} formKind={formKind} setFormKind={setFormKind} displaySelected={displaySelected} /> : <EditImago parent={props.parent} destroyForm={handleDestroyForm} formKind={formKind} setFormKind={setFormKind} displaySelected={displaySelected} foodGroups={foodGroups} />}
                </div>
                : <div />}
        </Container>
    )
}