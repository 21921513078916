import React, { useState, useEffect } from 'react'
import {Row, Col, Button, Form } from 'react-bootstrap'
import { labelFetch } from '../../../api/fetch'
import { TextInput } from './commons/TextInput'
import { Uploader } from './commons/Uploader'

import './labelForms.css'

export const EditLabel = (props) => {

    const [name, setName] = useState("")
    const [imageUrl, setImageUrl] = useState("")

    useEffect(() => {
        console.log(props.elementSelected)
        setName(props.elementSelected.name)
    }, [props.elementSelected])

    const handleSubmit = () => {

        let image = imageUrl
        if (image === "") {
            image = props.elementSelected.image
        }

        let label = {
            id: props.elementSelected.id,
            name: name,
            image: image,
            kind: "LABEL",
            place_id: parseInt(props.parent.id.split('-')[1]),
            name_secondary: "",
            created_at: props.elementSelected.created_at,
            order: props.elementSelected.order,
            cover_image: props.elementSelected.cover_image
        }

        postLabel(label)
    }

    const postLabel = (async(label) => {
        const method = 'PUT'
        const body = JSON.stringify(label)
        const headers = {
            'Content-Type': 'application/json',
        }
        await labelFetch({ method, body, headers }).then(() => {props.destroyForm()});
    })

    return (
        <Form>
            <Row className="first-row">
                <Col xs={5}>
                    <Form.Label>*Name</Form.Label>
                    <TextInput value={name} setValue={setName} />
                </Col>
                <Col xs={7}>
                    <Form.Label>*Image</Form.Label>
                    <Uploader setValue={setImageUrl} value={imageUrl} />
                </Col>
            </Row>
            <Row className="justify-content-end submit-button" >
                <Col xs={2} className="text-end">
                    <Button className="bg-dark-blue" onClick={() => handleSubmit()}>Save</Button>
                </Col>
            </Row>
        </Form>
    )
}