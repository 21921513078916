import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { editCompany } from '../../../api/fetch';
import { CheckboxInput } from './commons/CheckboxInput';
import { TextInput } from './commons/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiceSix } from '@fortawesome/free-solid-svg-icons'
import { generateRandomNumber } from '../../../utils/Functions';


import './forms.css'

export const EditCompany = (props) => {

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [isMobile, setIsMobile] = useState(false)
    const [appFontBoldFamilyDynamic, setAppFontBoldFamilyDynamic] = useState("font_family_open_sans_light")
    const [appFontFamilyDynamic, setAppFontFamilyDynamic] = useState("font_family_open_sans_light")
    const [accentDynamicColor, setAccentDynamicColor] = useState("")
    const [accentTextColor, setAccentTextColor] = useState("")
    const [bgFluidityDynamicColor, setBgFluidityDynamicColor] = useState("")
    const [accentDisableDynamicColor, setAccentDisableDynamicColor] = useState("")
    const [errorDynamicColor, setErrorDynamicColor] = useState("")
    const [appCode, setAppCode] = useState("")
    const [whithelist, setWhitelist] = useState("")
    const [isFormError, setIsFormError] = useState(false)

    useEffect(() => {

        setName(props.elementSelected.name)
        setDescription(props.elementSelected.description)

        if (props.elementSelected.mb_template) {
            const mb_template = props.elementSelected.mb_template
            setIsMobile(true)
            setAppFontBoldFamilyDynamic(mb_template.app_font_bold_family_dynamic)
            setAppFontFamilyDynamic(mb_template.app_font_family_dynamic)
            setAccentDynamicColor(mb_template.accent_dynamic_color)
            setAccentTextColor(mb_template.accent_text_dynamic_color)
            setBgFluidityDynamicColor(mb_template.bg_fluidity_dynamic_color)
            setAccentDisableDynamicColor(mb_template.accent_disable_dynamic_color)
            setErrorDynamicColor(mb_template.error_dynamic_color)
            setAppCode(props.elementSelected.appCode)
            setWhitelist(props.elementSelected.whitelist.join())
        }
        else {
            setIsMobile(false)
            setAppFontBoldFamilyDynamic("")
            setAppFontFamilyDynamic("")
            setAccentDynamicColor("")
            setAccentTextColor("")
            setBgFluidityDynamicColor("")
            setAccentDisableDynamicColor("")
            setErrorDynamicColor("")
            setAppCode("")
            setWhitelist("")

        }

    }, [props.elementSelected])

    const handleFontBoldFamilyChange = (e) => {
        setAppFontBoldFamilyDynamic(e.target.value)
    }
    const handleFontFamilyChange = (e) => {
        setAppFontFamilyDynamic(e.target.value)
    }
    const handleWhithelistChange = (e) => {
        setWhitelist(e.target.value)
    }

    const handleSubmit = () => {

        if (!name) {
            setIsFormError(true)
            return
        }

        let mbTemplate = null
        if (isMobile) {
            if (!(appFontBoldFamilyDynamic && appFontFamilyDynamic && accentDynamicColor && accentTextColor && bgFluidityDynamicColor && accentDisableDynamicColor && errorDynamicColor && appCode)) {
                setIsFormError(true)
                return
            }
            mbTemplate = {
                app_font_bold_family_dynamic: appFontBoldFamilyDynamic,
                app_font_family_dynamic: appFontFamilyDynamic,
                accent_dynamic_color: accentDynamicColor,
                accent_text_dynamic_color: accentTextColor,
                bg_fluidity_dynamic_color: bgFluidityDynamicColor,
                accent_disable_dynamic_color: accentDisableDynamicColor,
                error_dynamic_color: errorDynamicColor
            }
        }
        setIsFormError(false)

        let companyWithRel = {
            company: {
                id: parseInt(props.elementSelected.id.split('-')[1]),
                name: name,
                description: description,
                mb_template: mbTemplate,
            },
            appCode: appCode,
            whiteList: whithelist.split(",")
        }

        postData(companyWithRel)
    }

    const postData = (async (companyWithRel) => {
        const method = 'PUT'
        const body = JSON.stringify(companyWithRel)
        const headers = {
            'Content-Type': 'application/json',
        }
        await editCompany({ method, body, headers }).then(() => { props.showSuccessForm() });
    })

    return (
        <Container>
            <Row className="title">
                <Col>
                    <h2>Edit Company</h2>
                </Col>
            </Row>
            <Row className="name-description">
                <Col>
                    <Form.Label>*Name</Form.Label>
                    <TextInput value={name} setValue={setName} />
                </Col>
                <Col>
                    <Form.Label>Description</Form.Label>
                    <TextInput value={description} setValue={setDescription} />
                </Col>
            </Row>
            <Row className="checkbox">
                <Col>
                    <CheckboxInput label="App mobile active" value={isMobile} setValue={setIsMobile} />
                </Col>
            </Row>
            <Row className="subtitle">
                <h3>App Style:</h3>
            </Row>
            <Row className="close-inputs">
                <Col xs={12} md={5}>
                    <Form.Label>app_font_bold_family_dynamic:</Form.Label>
                </Col>
                <Col xs={12} md={7}>
                    <Form.Select label="Default select example" disabled={!isMobile} value={appFontBoldFamilyDynamic} onChange={(e) => handleFontBoldFamilyChange(e)} >
                        <option value="font_family_open_sans_light">font_family_open_sans_light</option>
                        <option value="font_family_open_sans_semibold">font_family_open_sans_semibold</option>
                        <option value="font_family_open_sans_bold">font_family_open_sans_bold</option>
                        <option value="font_family_avenir_next_cyr_regular">font_family_avenir_next_cyr_regular</option>
                        <option value="font_family_avenir_next_cyr_medium">font_family_avenir_next_cyr_medium</option>
                        <option value="font_family_avenir_next_cyr_bold">font_family_avenir_next_cyr_bold</option>
                        <option value="font_family_helvetica_neue_light">font_family_helvetica_neue_light</option>
                        <option value="font_family_helvetica_neue_medium">font_family_helvetica_neue_medium</option>
                        <option value="font_family_helvetica_neue_bold">font_family_helvetica_neue_bold</option>
                        <option value="font_family_roboto_light">font_family_roboto_light</option>
                        <option value="font_family_roboto_medium">font_family_roboto_medium</option>
                        <option value="font_family_roboto_black">font_family_roboto_black</option>
                        <option value="font_family_montserrat_regular">font_family_montserrat_regular</option>
                        <option value="font_family_montserrat_semibold">font_family_montserrat_semibold</option>
                        <option value="font_family_montserrat_extrabold">font_family_montserrat_extrabold</option>
                    </Form.Select>
                    <p className="font-size-12">Text color when text is bold</p>
                </Col>
            </Row>
            <Row className="close-inputs">
                <Col xs={12} md={5}>
                    <Form.Label>app_font_family_dynamic:</Form.Label>
                </Col>
                <Col xs={12} md={7}>
                    <Form.Select label="Default select example" disabled={!isMobile} value={appFontFamilyDynamic} onChange={(e) => handleFontFamilyChange(e)} >
                        <option value="font_family_open_sans_light">font_family_open_sans_light</option>
                        <option value="font_family_open_sans_semibold">font_family_open_sans_semibold</option>
                        <option value="font_family_open_sans_bold">font_family_open_sans_bold</option>
                        <option value="font_family_avenir_next_cyr_regular">font_family_avenir_next_cyr_regular</option>
                        <option value="font_family_avenir_next_cyr_medium">font_family_avenir_next_cyr_medium</option>
                        <option value="font_family_avenir_next_cyr_bold">font_family_avenir_next_cyr_bold</option>
                        <option value="font_family_helvetica_neue_light">font_family_helvetica_neue_light</option>
                        <option value="font_family_helvetica_neue_medium">font_family_helvetica_neue_medium</option>
                        <option value="font_family_helvetica_neue_bold">font_family_helvetica_neue_bold</option>
                        <option value="font_family_roboto_light">font_family_roboto_light</option>
                        <option value="font_family_roboto_medium">font_family_roboto_medium</option>
                        <option value="font_family_roboto_black">font_family_roboto_black</option>
                        <option value="font_family_montserrat_regular">font_family_montserrat_regular</option>
                        <option value="font_family_montserrat_semibold">font_family_montserrat_semibold</option>
                        <option value="font_family_montserrat_extrabold">font_family_montserrat_extrabold</option>
                    </Form.Select>
                    <p className="font-size-12">Text color when text is regular</p>
                </Col>
            </Row>
            <Row className="close-inputs">
                <Col xs={12} md={5}>
                    <Form.Label>*accent_dynamic_color:</Form.Label>
                </Col>
                <Col xs={12} md={7}>
                    <TextInput value={accentDynamicColor} setValue={setAccentDynamicColor} disabled={!isMobile} />
                    <p className="font-size-12">Primary color</p>
                </Col>
            </Row>
            <Row className="close-inputs">
                <Col xs={12} md={5}>
                    <Form.Label>*accent_text_color:</Form.Label>
                </Col>
                <Col xs={12} md={7}>
                    <TextInput value={accentTextColor} setValue={setAccentTextColor} disabled={!isMobile} />
                    <p className="font-size-12">Text color when inside elements of primary color</p>
                </Col>
            </Row>
            <Row className="close-inputs">
                <Col xs={12} md={5}>
                    <Form.Label>*bg_fluidity_dynamic_color:</Form.Label>
                </Col>
                <Col xs={12} md={7}>
                    <TextInput value={bgFluidityDynamicColor} setValue={setBgFluidityDynamicColor} disabled={!isMobile} />
                    <p className="font-size-12">Fluidity background color</p>
                </Col>
            </Row>
            <Row className="close-inputs">
                <Col xs={12} md={5}>
                    <Form.Label>*accent_disable_dynamic_color:</Form.Label>
                </Col>
                <Col xs={12} md={7}>
                    <TextInput value={accentDisableDynamicColor} setValue={setAccentDisableDynamicColor} disabled={!isMobile} />
                    <p className="font-size-12">accent_dynamic_color for disabled elements</p>
                </Col>
            </Row>
            <Row className="close-inputs">
                <Col xs={12} md={5}>
                    <Form.Label>*error_dynamic_color:</Form.Label>
                </Col>
                <Col xs={12} md={7}>
                    <TextInput value={errorDynamicColor} setValue={setErrorDynamicColor} disabled={!isMobile} />
                    <p className="font-size-12">Color of warnings</p>
                </Col>
            </Row>
            <Row className="name-description">
                <Col xs={12} md={5}>
                    <Form.Label><h4>*App Code:</h4></Form.Label>
                </Col>
                <div className="d-flex flex-row text-center align-items-center">
                    <Col xs={5} md={7} >
                        <div className="d-flex flex-row align-items-center">
                            <Form.Control type="text" value={appCode} disabled />
                            {isMobile ? <FontAwesomeIcon className="c-black mx-3 button" icon={faDiceSix} onClick={() => setAppCode(generateRandomNumber())} /> : <></>}
                        </div>
                    </Col>
                </div>
            </Row>
            <Row className="name-description">
                <Col xs={12}>
                    <Form.Label><h4>Whitelist:</h4></Form.Label>
                    <Form.Control as="textarea" rows={2} value={whithelist} onChange={(e) => handleWhithelistChange(e)} disabled={!isMobile} />
                </Col>
            </Row>
            <Row className="justify-content-end submit-button" >
                <Col xs={2} className="text-end">
                    <Button className="bg-dark-blue" onClick={() => handleSubmit()}>Apply</Button>
                </Col>
            </Row>
            {isFormError ? <Row className="error-message">Fill the iNputs marked with *</Row> : <div />}
        </Container>
    )
}