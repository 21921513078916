import React, { useState, useEffect } from 'react';
import { getRegions, regionFetch } from '../../api/fetch';
import { Container, Row, Col, Table, Image, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { AlertModal } from './forms/commons/AlertModal'
import { AddRegion } from './forms/AddRegion';
import { EditRegion } from './forms/EditRegion';


export const Regions = (props) => {

    const [regions, setRegions] = useState([])
    const [loading, setLoading] = useState(false)
    const [regionSelected, setRegionSelected] = useState({})
    const [regionToDelete, setRegionToDelete] = useState({})
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showAddForm, setShowAddForm] = useState(false)
    const [showEditForm, setShowEditForm] = useState(false)
    const [showDeleteError, setShowDeleteError] = useState(false)

    useEffect(() => {
        setShowDeleteError(false)
        if (!loading) {
            fetchRegions(props.parent.id.split('-')[1])
        }
    }, [props.parent])

    const fetchRegions = (async (id) => {
        setLoading(true)
        setRegions([])
        const method = 'GET'
        const result = await getRegions({ method, id });
        if (result) setRegions(result)
        setLoading(false)
    })
    
    const deleteRegion = (async () => {
        setShowDeleteError(false)
        setLoading(true)
        const method = "DELETE"
        const body = JSON.stringify(regionToDelete)
        const headers = {
            'Content-Type': 'application/json',
        }
        const result = await regionFetch({ method, body, headers }).then((result) => {
            if (result === "DELETE_NOT_ALLOWED") {
                setShowDeleteError(true)
            }
            else {
                fetchRegions(props.parent.id.split('-')[1])
            }
            setLoading(false)
        })
    })

    const handleEdit = (region) => {
        setShowAddForm(false)
        setRegionSelected(region)
        setShowEditForm(true)
    }

    const handleDelete = (region) => {
        setRegionToDelete(region)
        setShowDeleteModal(true)
    }

    const handleAddRegion = () => {
        setShowEditForm(false)
        setShowAddForm(true)
    }

    const handleDestroyForms = () => {
        setShowDeleteError(false)
        setShowAddForm(false)
        setShowEditForm(false)
        fetchRegions(props.parent.id.split('-')[1])
    }

    return (
        <Container>
            {showDeleteModal ?
                <Row>
                    <Col>
                        <AlertModal message="Are you sure you want to delete?" primaryAction={deleteRegion} show={showDeleteModal} setShow={setShowDeleteModal} element={regionToDelete} />
                    </Col>
                </Row>
                : <div />
            }
            <Row className="title">
                <Col>
                    <h2>{props.parent.name + " > Regions"}</h2>
                </Col>
            </Row>
            {loading ? <div>Loading...</div> :
                <div id="labels-table" className="scrollable">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <>
                                {regions.map(region => (
                                    <tr key={region.id}>
                                        <td>{region.id}</td>
                                        <td>{region.name}</td>
                                        <td><FontAwesomeIcon className="c-black me-2 button" icon={faEdit} onClick={() => handleEdit(region)} /></td>
                                        <td><FontAwesomeIcon className="c-black me-2 button" icon={faTrash} onClick={() => handleDelete(region)} /></td>
                                    </tr>
                                ))}
                            </>
                        </tbody>
                    </Table>
                </div>
            }
            {regions.length === 0 ? <Row className="no-label-text"><Col className="text-center font-size-20">No regions</Col></Row> : <div />}
            <Button className="bg-dark-blue mt-2" variant="primary" type="button" onClick={() => handleAddRegion()}>
                Add Region
            </Button>
            {showAddForm ? <AddRegion parent={props.parent} destroyForm={handleDestroyForms} /> : <div />}
            {showEditForm ? <EditRegion parent={props.parent} elementSelected={regionSelected} destroyForm={handleDestroyForms} /> : <div />}
            {showDeleteError ? <Row className="error-message">Region cannot be delete because it already has associations.</Row> : <div />}
        </Container>
    )
}