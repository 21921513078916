import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { CheckboxInput } from './commons/CheckboxInput';
import { NumberInput } from './commons/NumberInput';
import { TextInput } from './commons/TextInput';
import { Uploader } from './commons/Uploader';
import ReactJson from 'react-json-view'
import { fluidityConfig } from '../../../utils/Constants';
import { ColumnDirective, ColumnsDirective, GridComponent, Filter, Toolbar, Inject } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { getSensorsOfRegion, regionFetch } from '../../../api/fetch';

export const AddRegion = (props) => {

    const [name, setName] = useState("")
    const [maxCapacity, setMaxCapacity] = useState(0)
    const [picto, setPicto] = useState("")
    const [kind, setKind] = useState("ZONE")
    const [fluidityConf, setFluidityConf] = useState(fluidityConfig)
    const [order, setOrder] = useState(0)
    const [showInMobile, setShowInMobile] = useState(false)
    const [subKind, setSubKind] = useState(null)
    const [sensors, setSensors] = useState([])
    const [loading, setLoading] = useState(false)
    const [formula, setFormula] = useState({ formula: [] })
    const [formulaString, setFormulaString] = useState("")
    const [render, setRender] = useState(false)
    const [isFormError, setIsFormError] = useState(false)
    const [isFirstTimeChecking, setIsFirstTimeChecking] = useState(false)

    const gridInstance = useRef(null)

    const toolbarOptions = ['Search'];
    const dropDownData = ['+', '-'];
    const selectionSettings = {
        checkboxOnly: true
    }

    useEffect(() => {
        setIsFirstTimeChecking(false)
    }, [])

    useEffect(() => {
        fetchSensors()
    }, [])

    //necessario usare localStorage causa handleChangeDropdownOperator
    useEffect(() => {
        
        let tempString = ""

        formula.formula.forEach((item => {
            sensors.forEach(item2 => {
                if (item.id_sensor_detail == item2.id) {
                    tempString += item2.operator + item2.sensor_id + "(" + item2.id + ")"
                }
            })
        }))

        setFormulaString(tempString)

        localStorage.setItem("string", tempString)
        localStorage.setItem("formula", JSON.stringify(formula))
    }, [formula])

    const fetchSensors = (async () => {
        setLoading(true)
        const method = 'GET'
        const id = 0
        const result = await getSensorsOfRegion({ method, id });
        if (result) setSensors(result)
        setLoading(false)
    })

    const handleSubmit = () => {

        if (!name) {
            setIsFormError(true)
            return
        }
        setIsFormError(false)

        let region = {
            name: name,
            max_capacity: parseInt(maxCapacity),
            picto: picto,
            kind: kind,
            fluidity_conf: fluidityConf,
            order: parseInt(order),
            formula: formula,
            show_in_mobile: showInMobile,
            sub_kind: subKind,
            place_id: parseInt(props.parent.id.split('-')[1])
        }

        if (!showInMobile) {
            region.sub_kind = null
            region.picto = ""
        }

        postRegion(region)
    }

    const postRegion = (async (region) => {

        const method = "POST"
        const body = JSON.stringify(region)
        const headers = {
            'Content-Type': 'application/json',
        }

        const result = await regionFetch({ method, body, headers }).then((result) => {
            if (result) {
                props.destroyForm()
            }
        })
    })

    const dropdown = (args) => {
        if (args.column.field === "Operator") {
            ReactDOM.render(<DropDownListComponent dataSource={dropDownData} value="+" onChange={(e) => handleChangeDropdownOperator(e, args)} />, args.cell)
        }
    }

    const handleChangeDropdownOperator = (e, args) => {

        let oldOperator = args.data.operator

        args.data.operator = e.target.value
        console.log(e)
        console.log(args)

        let tempFormula = JSON.parse(localStorage.getItem("formula"))

        const index = tempFormula.formula.findIndex(x => x.id_sensor_detail === args.data.id)

        if (index !== -1) {
            tempFormula.formula[index].operator = args.data.operator
            setFormula(tempFormula)

            //bisogna usare il local storage perchè la variabile di stato formulaString non è accesibile da questa funzione per qualche motivo...
            let tempString = localStorage.getItem("string")
            tempString = tempString.replace(oldOperator + args.data.sensor_id + "(" + args.data.id + ")", e.target.value + args.data.sensor_id + "(" + args.data.id + ")")
            setFormulaString(tempString)
        }
    }

    const onRowSelected = (args) => {

        if (isFirstTimeChecking) {
            return
        }

        let tempFormula = formula.formula

        let objectToAdd = {
            id_sensor_detail: args.data.id,
            operator: args.data.operator
        }
        tempFormula.push(objectToAdd)

        let tempObj = {
            formula: tempFormula
        }

        setFormula(tempObj)
    }

    const onRowDeselected = (args) => {

        if (isFirstTimeChecking) {
            return
        }

        let tempFormula = formula.formula.filter(function (obj) {
            return obj.id_sensor_detail !== args.data.id;
        });
        let tempObj = {
            formula: tempFormula
        }
        setFormula(tempObj)
    }

    const actionBegin = (args) => {
        if (args.requestType === "searching") {
            setIsFirstTimeChecking(true)
        }
    }

    const actionComplete = (args) => {
        if (args.requestType === "searching") {
            let newSelectedSensors = []
            args.rows.forEach(row => {
                const found = formula.formula.find(element => element.id_sensor_detail === row.data.id)
                if (found) {
                    newSelectedSensors.push(row.index)
                }
            })
            gridInstance.current.selectRows(newSelectedSensors)
            setIsFirstTimeChecking(false)
        }
    }

    return (
        <Form className="pt-4">
            <Row>
                <Col xs={12} md={6}>
                    <Form.Label>Name</Form.Label>
                    <TextInput value={name} setValue={setName} />
                </Col>
                <Col xs={12} md={6}>
                    <Form.Label>Kind</Form.Label>
                    <Form.Select aria-label="Default select example" value={kind} onChange={(e) => setKind(e.target.value)}>
                        <option value="ZONE">ZONE</option>
                        <option value="STAND">STAND</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row className="pt-4">
                <Col xs={3}>
                    <Form.Label>Max capacity</Form.Label>
                    <NumberInput value={maxCapacity} setValue={setMaxCapacity} />
                </Col>
                <Col xs={3}>
                    <Form.Label>Order</Form.Label>
                    <NumberInput value={order} setValue={setOrder} />
                </Col>
                <Col xs={3}>
                    <CheckboxInput label="Show in mobile" value={showInMobile} setValue={setShowInMobile} />
                </Col>
            </Row>
            {!showInMobile ? null :
                <Row className="pt-4">
                    <Col xs={4}>
                        <Form.Label>Subkind</Form.Label>
                        <Form.Select aria-label="Default select example" value={subKind} onChange={(e) => setSubKind(e.target.value)}>
                            <option value="ZDR">ZDR</option>
                            <option value="ZDD">ZDD</option>
                        </Form.Select>
                    </Col>
                    <Col xs={6}>
                        <Form.Label>Picto</Form.Label>
                        <Uploader value={picto} setValue={setPicto} />
                    </Col>
                </Row>
            }
            <Row className="pt-4 pb-4">
                <Col xs={12}>
                    <Form.Label>Fluidity conf</Form.Label>
                    <ReactJson src={fluidityConf} theme="monokai" onAdd={(fluidityConf) => setFluidityConf(fluidityConf.updated_src)} onEdit={(fluidityConf) => setFluidityConf(fluidityConf.updated_src)} onDelete={(fluidityConf) => setFluidityConf(fluidityConf.updated_src)} />
                </Col>
            </Row>
            <h4>Sensor Details:</h4>
            {loading || !sensors ? <span>Loading Sensors...</span> :
                <>
                    <Row className="pb-5">
                        <Col xs={12}>
                            <GridComponent
                                ref={gridInstance}
                                dataSource={sensors}
                                toolbar={toolbarOptions}
                                height={200}
                                queryCellInfo={dropdown}
                                rowSelected={onRowSelected}
                                rowDeselected={onRowDeselected}
                                selectionSettings={selectionSettings}
                                actionBegin={actionBegin}
                                actionComplete={actionComplete}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective type='checkbox' width='20' />
                                    <ColumnDirective field='id' headerText='Identifier' width='20' />
                                    <ColumnDirective field='sensor_id' headerText='Sensor ID' width='40' />
                                    <ColumnDirective field='kind' headerText='Kind' width='20' />
                                    <ColumnDirective field='detail_data' headerText='Detail Data' width='80' />
                                    <ColumnDirective field="Operator" headerText='Operator' width='20' />
                                </ColumnsDirective>
                                <Inject services={[Filter, Toolbar]} />
                            </GridComponent>
                        </Col>
                    </Row>
                    <Row className="pb-5">
                        <Col>
                            <Form.Label>Formula</Form.Label>
                            <div className="border border-dark rounded">
                                <h6 className='pb-2 pt-2 wrap'>{formulaString === "" ? "No sensor selected" : formulaString}</h6>
                            </div>
                        </Col>
                    </Row>
                    <Row className="pb-5">
                        <Col>
                            <Form.Label>Formula Json</Form.Label>
                            <ReactJson src={formula} theme="monokai" />
                        </Col>
                    </Row>
                </>
            }
            <Row className="justify-content-end submit-button" >
                <Col xs={2} className="text-end">
                    <Button className="bg-dark-blue" onClick={() => handleSubmit()}>Save</Button>
                </Col>
            </Row>
            {isFormError ? <Row className="error-message">Fill the inputs marked with *</Row> : <div />}
        </Form>
    )
}