import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Table } from 'react-bootstrap'
import { displayDeviceFetch, displayFetch, checkHwidUniqness } from '../../../api/fetch'
import { CheckboxInput } from './commons/CheckboxInput';
import { NumberInput } from './commons/NumberInput';
import { SvgUploader } from './commons/SvgUploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faPlus, faDiceSix } from '@fortawesome/free-solid-svg-icons'
import { generateRandom } from '../../../utils/Functions';
import { TextInput } from './commons/TextInput'
import { AlertModal } from './commons/AlertModal'
import { AlertSingle } from './commons/AlertSingle';


export const EditDisplay = (props) => {

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [mapUrl, setMapUrl] = useState("")
    const [order, setOrder] = useState(0)
    const [visibleDashboard, setVisibleDashboard] = useState(false)
    const [hwid, setHwid] = useState("")
    const [hwids, setHwids] = useState([])
    const [hwidToDelete, setHwidToDelete] = useState([])
    const [showHwidModal, setShowHwidModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [modalMessage, setModalMessage] = useState("")
    const [showError, setShowError] = useState(false)
    const [isFormError, setIsFormError] = useState(false)
    const [render, setRender] = useState(false)
    const [showErrorMessageDuplicateHwid, setShowErrorMessageDuplicateHwid] = useState(false)

    useEffect(() => {
        console.log(props.displaySelected)
        setName(props.displaySelected.display.name)
        setDescription(props.displaySelected.display.description)
        setOrder(props.displaySelected.display.order)
        setVisibleDashboard(props.displaySelected.display.visible_dashboard)
        setHwids([])

        if (props.displaySelected.devices) {
            let temp = []
            props.displaySelected.devices.forEach(device => {
                temp.push(device.hwid)
            })
            setHwids(temp)
        }

        localStorage.setItem("template", JSON.stringify(props.displaySelected.display.template))

    }, [props.displaySelected])

    const postDisplay = (async (display) => {
        const method = "PUT"
        const body = JSON.stringify(display)
        const headers = {
            'Content-Type': 'application/json',
        }
        await displayFetch({ method, body, headers }).then(() => { props.destroyForm() });
    })

    const checkDevice = (async () => {

        if (hwids.includes(hwid)) {
            setShowErrorMessageDuplicateHwid(true)
            return
        }
        setShowErrorMessageDuplicateHwid(false)

        const method = "GET"
        const headers = {
            'Content-Type': 'application/json',
        }

        const result = await checkHwidUniqness({ method, headers, hwid })

        if (result === "ERROR") {
            setShowError(true)
            return
        }

        if (!result) {
            setModalMessage("Hwid does not exist, create new Device?")
            setShowHwidModal(true)
        }
        else {
            if (!result.display_id) {
                setModalMessage("Device already exist and is associated with place_id: " + result.place_id + " and no displays, overwrite?")
            }
            else {
                setModalMessage("Device already exist and is associated with: PLACE_ID: " + result.place_id + " and DISPLAY_ID: " + result.display_id + ", overwrite?")
            }
            setShowHwidModal(true)
        }
    })

    const handleSubmit = () => {

        if (!(name)) {
            setIsFormError(true)
            return
        }
        setIsFormError(false)

        let svg = mapUrl

        if (!svg) {
            svg = props.displaySelected.map_picto
        }

        let display = props.displaySelected.display

        display.name = name
        display.description = description
        display.map_picto = svg
        display.order = parseInt(order)
        display.visible_dashboard = visibleDashboard

        let devices = []

        hwids.forEach(hwid => {
            const device = {
                hwid: hwid
            }
            devices.push(device)
        })

        let displayWithRel = {
            display: display,
            devices: devices,
        }

        postDisplay(displayWithRel)


        postDisplay(displayWithRel)
    }

    const handleRemoveHwid = (hwidToDelete) => {

        setHwidToDelete(hwidToDelete)
        setShowDeleteModal(true)

        // deleteDeviceAssociation(hwidToDelete)
        //     .then(() => { setHwids(currentHwids => currentHwids.filter((theHwid) => theHwid !== hwidToDelete)) });


        // setHwids(currentHwids => currentHwids.filter((theHwid) => theHwid !== hwidToDelete));
    }

    const deleteDeviceAssociation = (async () => {

        const method = "DELETE"
        const headers = {
            'Content-Type': 'application/json',
        }
        const display_id = props.displaySelected.display.id
        await displayDeviceFetch({ method, headers, hwidToDelete, display_id })
            .then(() => { setHwids(currentHwids => currentHwids.filter((theHwid) => theHwid !== hwidToDelete)) });

        setRender(!render)

        // setHwids(currentHwids => currentHwids.filter((theHwid) => theHwid !== hwidToDelete));
    })

    const addHwid = () => {

        if (!hwid) {
            setIsFormError(true)
            return
        }
        setIsFormError(false)

        setHwids([...hwids, hwid])
        setHwid("")
    }


    return (
        <Form className="pb-5">
            <Row className="first-row">
                <Col>
                    <Form.Label>KIND</Form.Label>
                    <Form.Select aria-label="Default select example" value={props.formKind} onChange={(e) => props.setFormKind(e.target.value)} disabled>
                        <option value="DISPLAYEDITOR">DISPLAYEDITOR</option>
                        <option value="IMAGOTAG">IMAGOTAG</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col xs={12} md={6}>
                    <Form.Label>*Name</Form.Label>
                    <TextInput value={name} setValue={setName} />
                </Col>
                <Col xs={12} md={6}>
                    <Form.Label>Description</Form.Label>
                    <TextInput value={description} setValue={setDescription} />
                </Col>
            </Row>
            <Row className="pt-2 pb-2">
                {/* <Col xs={12} md={7}>
                    <Form.Label>Map picture</Form.Label>
                    <SvgUploader setValue={setMapUrl} />
                </Col> */}
                <Col xs={5} md={2}>
                    <Form.Label>Order</Form.Label>
                    <NumberInput value={order} setValue={setOrder} />
                </Col>
                <Col xs={5} md={3} className="visible-dashboard">
                    <CheckboxInput label="Visible dashboard" value={visibleDashboard} setValue={setVisibleDashboard} />
                </Col>
                <Col xs={2} md={3} className="visible-dashboard">
                    <a href="/template" target="_blank">View template</a>
                </Col>
            </Row>
            <Row>
                <Row className="pb-2 pt-4">
                    <Col>
                        <h4 className="font-size-18">Associated Displays:</h4>
                    </Col>
                </Row>
                <div id="display-table" className="scrollable-small">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Hwid</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <>
                                {hwids.map(hwid => (
                                    <tr key={hwid}>
                                        <td>{hwid}</td>
                                        <td><FontAwesomeIcon className="c-black me-2 button" icon={faTrash} onClick={() => handleRemoveHwid(hwid)} /></td>
                                    </tr>
                                ))}
                            </>
                        </tbody>
                    </Table>
                </div>
            </Row>
            <Row className="pt-2">
                <Col xs={12} md={6}>
                    <Form.Label>
                        Add Device
                    </Form.Label>
                    <div className="d-flex flex-row align-items-center">
                        <TextInput value={hwid} setValue={setHwid} />
                        <FontAwesomeIcon className="c-black mx-3 button" icon={faDiceSix} onClick={() => setHwid(generateRandom())} />
                        <FontAwesomeIcon className="c-green me-3 button" icon={faPlus} onClick={() => checkDevice()} />
                    </div>
                </Col>
            </Row>
            <Row className="justify-content-end submit-button" >
                <Col xs={2} className="text-end">
                    <Button className="bg-dark-blue" onClick={() => handleSubmit()}>Apply</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AlertSingle message="An error has occurred. Please try again later" show={showError} setShow={setShowError} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <AlertModal message={modalMessage} primaryAction={addHwid} show={showHwidModal} setShow={setShowHwidModal} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <AlertModal message="Are you sure you want to delete this association? This action can't be reverted" primaryAction={deleteDeviceAssociation} show={showDeleteModal} setShow={setShowDeleteModal} />
                </Col>
            </Row>
            {isFormError ? <Row className="error-message">Fill the inputs marked with *</Row> : <div />}
            {showErrorMessageDuplicateHwid ? <Row className="error-message">Hwid already inserted</Row> : <div />}
        </Form>
    )
}