import React from 'react'
import { Form } from 'react-bootstrap'
import { upload } from '../../../../api/fetch'


export const SvgUploader = (props) => {

    const handleChangeImage = (async (e) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        const result = await e.target.files[0].text()
        props.setValue(result)
    })

    return (
        <div>
            <Form.Control type="file" onChange={(e) => handleChangeImage(e)} accept="image/svg+xml" />
            {props.value ? <p className="font-size-12 ml-2">Svg present</p> : <span/>}
        </div>
    )
}