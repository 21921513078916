import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Sidebar } from './Sidebar'
import { AddCompany } from './forms/AddCompany'
import { EditCompany } from './forms/EditCompany';
import { AddContract } from './forms/AddContract';
import { EditContract } from './forms/EditContract';
import { AddSite } from './forms/AddSite';
import { EditSite } from './forms/EditSite';

import { Success } from './Success'
import { getObjectFromNestedArrayByID } from '../../utils/Functions'
import { Labels } from './Labels';
import { Displays } from './Displays'
import { AddRestaurant } from './forms/AddRestaurant';
import { EditRestaurant } from './forms/EditRestaurant';
import { FoodGroups } from './FoodGroups';
import { AddDevice } from './forms/AddDevice';
import { Sensors } from './Sensors';
import { Regions } from './Regions';
import { Users } from './Users';
import { Categories } from './Categories';
import { Redirect } from 'react-router';

export default function Dashboard() {

    const [data, setData] = useState([])
    const [elementSelected, setElementSelected] = useState({})
    const [nodeSelected, setNodeSelected] = useState({})
    const [parentOfElementSelected, setParentOfElementSelected] = useState({})
    const [form, setForm] = useState(<></>)
    const [operationCompleted, setOperationCompleted] = useState(false)
    const [toolbarElementSelected, setToolbarElementSelected] = useState("")

    const selectForm = (id) => {
        if (!id) {
            setForm(<></>)
            return
        }
        if (id.includes("companies-")) {
            setForm(<EditCompany elementSelected={elementSelected} showSuccessForm={showSuccessForm} />)
        }
        else if (id === "companies") {
            setForm(<AddCompany showSuccessForm={showSuccessForm} />)
        }
        if (id.includes("-contracts")) {
            setForm(<AddContract parent={parentOfElementSelected} showSuccessForm={showSuccessForm} />)
        }
        else if (id.includes("contracts-")) {
            setForm(<EditContract elementSelected={elementSelected} parent={parentOfElementSelected} showSuccessForm={showSuccessForm} />)
        }
        if (id.includes("-sites")) {
            setForm(<AddSite parent={parentOfElementSelected} showSuccessForm={showSuccessForm} />)
        }
        else if (id.includes("sites-")) {
            setForm(<EditSite parent={parentOfElementSelected} elementSelected={elementSelected} showSuccessForm={showSuccessForm} />)
        }
        if (id.includes("-labels")) {
            setForm(<Labels parent={parentOfElementSelected} />)
        }
        if (id.includes("-restaurant")) {
            setForm(<AddRestaurant parent={parentOfElementSelected} nodeSelected={nodeSelected} data={data} showSuccessForm={showSuccessForm} />)
        }
        else if (id.includes("restaurants-")) {
            setForm(<EditRestaurant parent={parentOfElementSelected} elementSelected={elementSelected} nodeSelected={nodeSelected} data={data} showSuccessForm={showSuccessForm} />)
        }
        if (id.includes("-displays")) {
            setForm(<Displays parent={parentOfElementSelected} />)
        }
        if (id.includes("-foodgroups")) {
            setForm(<FoodGroups parent={parentOfElementSelected} />)
        }
        if (id.includes("-regions")) {
            setForm(<Regions parent={parentOfElementSelected} />)
        }
        if (id.includes("-users")) {
            setForm(<Users parent={parentOfElementSelected} />)
        }
        if (id.includes("-categories")) {
            setForm(<Categories parent={parentOfElementSelected} />)
        }
        //TOOLBAR
        if (id === "DEVICE") {
            setToolbarElementSelected("")
            setForm(<AddDevice showSuccessToolbar={showSuccessToolbar} />)
        }
        if (id === "SENSOR") {
            setToolbarElementSelected("")
            setForm(<Sensors showSuccessToolbar={showSuccessToolbar} />)
        }
        //END TOOLBAR
    }

    useEffect(() => {
        setParentOfElementSelected(getObjectFromNestedArrayByID(data, nodeSelected.parentID))
        setElementSelected(getObjectFromNestedArrayByID(data, nodeSelected.id))
        selectForm(elementSelected.id)
    }, [nodeSelected, elementSelected])

    useEffect(() => {
        switch (toolbarElementSelected) {
            case "DEVICE":
                selectForm("DEVICE")
                break;
            case "SENSOR":
                selectForm("SENSOR")
                break;
            default:
                return
        }
    }, [toolbarElementSelected])

    const showSuccessForm = () => {
        setOperationCompleted(true)
        setForm(<Success />)
    }

    const showSuccessToolbar = () => {
        setForm(<Success />)
    }

    return (
        <>
            {!localStorage.getItem("loggedIn") ? <Redirect to="/" /> :
                <Container className="control-section" fluid>
                    <Sidebar data={data} setData={setData} elementSelected={elementSelected} setElementSelected={setElementSelected} nodeSelected={nodeSelected} setNodeSelected={setNodeSelected} operationCompleted={operationCompleted} setOperationCompleted={setOperationCompleted} setToolbarElementSelected={setToolbarElementSelected} />
                    <Row className="justify-content-md-center">
                        <Col md={8} xl={6} lg={8}>
                            {form}
                        </Col>
                    </Row>
                </Container>
            }
        </>
    )
}