import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { fetchGeolocation, fetchRestaurant } from '../../../api/fetch';
import { getObjectFromNestedArrayByID } from '../../../utils/Functions';
import { TextInput } from './commons/TextInput'
import { NumberInput } from './commons/NumberInput'
import { Uploader } from './commons/Uploader'
import { TooltipComponent } from './commons/TooltipComponent'

import './forms.css'

export const AddRestaurant = (props) => {

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [logo, setLogo] = useState("")
    const [isMobile, setIsMobile] = useState(false)
    const [coordinatesAddress, setCoordinatesAddress] = useState("")
    const [longitude, setLongitude] = useState(0.00)
    const [latitude, setLatitude] = useState(0.00)
    const [isErrorAddress, setIsErrorAddress] = useState(false)
    const [address, setAddress] = useState("")
    const [coverImage, setCoverImage] = useState("")
    const [isFormError, setIsFormError] = useState(false)
    const [contractID, setContractID] = useState(0)
    const [order, setOrder] = useState(0)

    const geoLocation = (async () => {
        const method = 'GET'
        const result = await fetchGeolocation({ method, coordinatesAddress })

        if (result.results.length === 0) {
            setIsErrorAddress(true)
            return
        }
        setIsErrorAddress(false)
        setLongitude(result.results[0].geometry.location.lng)
        setLatitude(result.results[0].geometry.location.lat)
        setAddress(result.results[0].formatted_address)
    })

    const postRestaurant = (async (restaurant) => {
        const method = 'POST'
        const body = JSON.stringify(restaurant)
        const headers = {
            'Content-Type': 'application/json',
        }

        await fetchRestaurant({ method, body, headers })

    })

    const handleSubmit = () => {

        if (!name) {
            setIsFormError(true)
            return
        }

        if (!(longitude && latitude && address) && isMobile) {
            setIsFormError(true)
            return
        }
        setIsFormError(false)

        let restaurantRequest = {
            restaurant: {
                name: name,
                description: description,
                address: address,
                picto_cover: coverImage,
                kind: "RESTAURANT",
                order: parseInt(order),
                template: {
                    client_logo: logo
                },
                contract_id: contractID,
                parent_id: parseInt(props.parent.id.split('-')[1])
            },
            longitude: longitude.toString(),
            latitude: latitude.toString()
        }

        postRestaurant(restaurantRequest).then(() => { props.showSuccessForm() })
    }

    //Search the parent company to set IsMobile
    useEffect(() => {
        if (!props.nodeSelected.id.includes("-restaurant")) return
        const placeParent = getObjectFromNestedArrayByID(props.data, props.nodeSelected.parentID)
        const contractParent = getObjectFromNestedArrayByID(props.data, "contracts-" + placeParent.place.contract_id)
        setContractID(placeParent.place.contract_id)
        const companyParent = getObjectFromNestedArrayByID(props.data, contractParent.parentID)

        if (companyParent.mb_template != null) {
            setIsMobile(true)
        }
    })

    return (
        <Container>
            <Row className="title">
                <Col>
                    <h2>{props.parent.name + " > Add Restaurant"}</h2>
                </Col>
            </Row>
            <Row className="name-description">
                <Col>
                    <Form.Label>*Name</Form.Label>
                    <TextInput value={name} setValue={setName} />
                </Col>
                <Col>
                    <Form.Label>Description</Form.Label>
                    <TextInput value={description} setValue={setDescription} />
                </Col>
            </Row>
            <Row className="name-description pb-4">
                <Col xs={10}>
                    <Form.Label>*Logo</Form.Label>
                    <Uploader setValue={setLogo} />
                </Col>
                <Col xs={2}>
                    <Form.Label>Order</Form.Label>
                    <NumberInput value={order} setValue={setOrder} />
                </Col>
            </Row>
            {isMobile ?
                <div>
                    <Row>
                        <h3>Mobile:</h3>
                    </Row>
                    <div className="border border-dark rounded">
                        <Form >
                            <Row className="coordinates justify-content-center">
                                <Col xs={12} md={8}>
                                    <Form.Label>*Coordinate</Form.Label>
                                    <TextInput value={coordinatesAddress} setValue={setCoordinatesAddress} />
                                </Col>
                                <Col xs={12} md={2} className="align-self-end" >
                                    <Button onClick={() => geoLocation()} className="mt-3 bg-dark-blue">Search</Button>
                                </Col>
                            </Row>
                            {isErrorAddress ? <Row className="error-message m-auto">Address not found</Row> : <div />}
                            <Row className="pt-3 justify-content-center">
                                <Col md={5}>
                                    <div className="d-flex align-items-baseline">
                                        <Form.Label>Longitude</Form.Label>
                                        <TooltipComponent />
                                    </div>
                                    <TextInput value={longitude} setValue={setLongitude} />
                                </Col>
                                <Col md={5}>
                                    <div className="d-flex align-items-baseline">
                                        <Form.Label>Latitude</Form.Label>
                                        <TooltipComponent />
                                    </div>
                                    <TextInput value={latitude} setValue={setLatitude} />
                                </Col>
                            </Row>
                            <Row className="pt-3 justify-content-center">
                                <Col xs={12} md={10}>
                                    <div className="d-flex align-items-baseline">
                                        <Form.Label>*Address</Form.Label>
                                        <TooltipComponent />
                                    </div>
                                    <TextInput value={address} setValue={setAddress} />
                                </Col>
                            </Row>
                            <Row className="pt-3 pb-4 justify-content-center">
                                <Col xs={10}>
                                    <Form.Label>Cover Image</Form.Label>
                                    <Uploader setValue={setCoverImage} value={coverImage} />
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                : <div />
            }
            <Row className="justify-content-end submit-button" >
                <Col className="text-end">
                    <Button className="bg-dark-blue" onClick={() => handleSubmit()}>Save</Button>
                </Col>
            </Row>
            {isFormError ? <Row className="error-message">Fill the inputs marked with *</Row> : <div />}
        </Container>
    )
}