import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap'
import { userFetch } from '../../../api/fetch';
import { CheckboxInput } from './commons/CheckboxInput';
import { TextInput } from './commons/TextInput';

export const EditSiteUser = (props) => {

    const [login, setLogin] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [type, setType] = useState("SITE_USER")
    const [isDemoMode, setIsDemoMode] = useState(false)
    const [hasNoWaste, setHasNoWaste] = useState(false)
    const [hasPrint, setHasPrint] = useState(false)
    const [hasPlanning, setHasPlanning] = useState(false)
    const [hasPrediction, setHasPrediction] = useState(false)
    const [hasEditoPlanning, setHasEditoPlanning] = useState(false)
    const [hasExportMenuCsv, setHasExportMenuCsv] = useState(false)
    const [hasAnalytics, setHasAnalytics] = useState(false)
    const [isFormError, setIsFormError] = useState(false)
    const [isUsernameAlreadyTakenError, setIsUsernameAlreadyTakenError] = useState(false)
    const [isGenericError, setIsGenericError] = useState(false)

    useEffect(() => {
        console.log(props.elementSelected)
        setLogin(props.elementSelected.login)
        setName(props.elementSelected.name)
        setEmail(props.elementSelected.email)
        setIsDemoMode(props.elementSelected.permissions.demo_mode)
        setType(props.elementSelected.permissions.type_user)

        if (props.elementSelected.features.includes("no_waste")) { setHasNoWaste(true) } else { setHasNoWaste(false) }
        if (props.elementSelected.features.includes("print")) { setHasPrint(true) } else { setHasPrint(false) }
        if (props.elementSelected.features.includes("planning")) { setHasPlanning(true) } else { setHasPlanning(false) }
        if (props.elementSelected.features.includes("prediction")) { setHasPrediction(true) } else { setHasPrediction(false) }
        if (props.elementSelected.features.includes("edito_planning")) { setHasEditoPlanning(true) } else { setHasEditoPlanning(false) }
        if (props.elementSelected.features.includes("export_menu_csv")) { setHasExportMenuCsv(true) } else { setHasExportMenuCsv(false) }
        if (props.elementSelected.features.includes("analytics")) { setHasAnalytics(true) } else { setHasAnalytics(false) }

    }, [props.elementSelected])

    const handleSubmit = () => {

        setIsUsernameAlreadyTakenError(false)
        setIsGenericError(false)

        if (!(name && login)) {
            setIsFormError(true)
            return
        }
        setIsFormError(false)

        let features = []

        if (hasNoWaste) { features.push("no_waste") }
        if (hasPrint) { features.push("print") }
        if (hasPlanning) { features.push("planning") }
        if (hasPrediction) { features.push("prediction") }
        if (hasEditoPlanning) { features.push("edito_planning") }
        if (hasExportMenuCsv) { features.push("export_menu_csv") }
        if (hasAnalytics) { features.push("analytics") }

        let user = props.elementSelected

        let permissions = {
            site_id: parseInt(props.parent.id.split('-')[1]),
            type_user: type,
            demo_mode: isDemoMode,
            company_id: parseInt(0)
        }

        user.name = name
        user.email = email
        user.login = login
        user.permissions = permissions
        user.features = features

        postUser(user)
    }

    const postUser = (async (user) => {
        const method = "PUT"
        const body = JSON.stringify(user)
        const headers = {
            'Content-Type': 'application/json',
        }

        const result = await userFetch({ method, body, headers }).then((result) => {
            if (result === "USERNAME_ALREADY_TAKEN") {
                setIsUsernameAlreadyTakenError(true)
                return
            }
            else if (result) {
                props.destroyForm()
            }
            else {
                setIsGenericError(true)
                return
            }
        })
    })

    return (
        <Form className="pt-4">
            <Row>
                <Col xs={12} md={6}>
                    <Form.Label>*Username</Form.Label>
                    <TextInput value={login} setValue={setLogin} />
                </Col>
            </Row>
            <Row className="pt-4">
                <Col xs={12} md={6}>
                    <Form.Label>*Name</Form.Label>
                    <TextInput value={name} setValue={setName} />
                </Col>
                <Col xs={12} md={6}>
                    <Form.Label>Email</Form.Label>
                    <TextInput value={email} setValue={setEmail} />
                </Col>
            </Row>
            <Row className="pt-4">
                <Col xs={10} md={6}>
                    <Form.Label>Type</Form.Label>
                    <Form.Select aria-label="Default select example" value={type} onChange={(e) => setType(e.target.value)}>
                        <option value="SITE_USER">Site user</option>
                        <option value="EXTERNAL_USER">External user</option>
                    </Form.Select>
                </Col>
            </Row>
            {type === "SITE_USER" ?
                <div>
                    <Row>
                        <h3 className="pt-4">Features:</h3>
                    </Row>
                    <div className="border border-dark rounded">
                        <Row className="pl-2">
                            <Col xs={3}>
                                <CheckboxInput label="no_waste" value={hasNoWaste} setValue={setHasNoWaste} />
                            </Col>
                            <Col xs={3}>
                                <CheckboxInput label="planning" value={hasPlanning} setValue={setHasPlanning} />
                            </Col>
                        </Row>
                        <Row className="pl-2">
                            <Col xs={3}>
                                <CheckboxInput label="prediction" value={hasPrediction} setValue={setHasPrediction} />
                            </Col>
                            <Col xs={3}>
                                <CheckboxInput label="edito_planning" value={hasEditoPlanning} setValue={setHasEditoPlanning} />
                            </Col>
                        </Row>
                        <Row className="pl-2">
                            <Col xs={3}>
                                <CheckboxInput label="export_menu_csv" value={hasExportMenuCsv} setValue={setHasExportMenuCsv} />
                            </Col>
                            <Col xs={3}>
                                <CheckboxInput label="analytics" value={hasAnalytics} setValue={setHasAnalytics} />
                            </Col>
                        </Row>
                        <Row className="pl-2">
                            <Col xs={3}>
                                <CheckboxInput label="print" value={hasPrint} setValue={setHasPrint} />
                            </Col>
                        </Row>
                    </div>
                    <Row className="pt-3 pb-3">
                        <Col xs={3}>
                            <CheckboxInput label="Demo mode" value={isDemoMode} setValue={setIsDemoMode} />
                        </Col>
                    </Row>
                </div> :
                <div />
            }
            <Row className="justify-content-end submit-button" >
                <Col xs={2} className="text-end">
                    <Button className="bg-dark-blue" onClick={() => handleSubmit()}>Save</Button>
                </Col>
            </Row>
            {isFormError ? <Row className="error-message">Fill the inputs marked with *</Row> : <div />}
            {isUsernameAlreadyTakenError ? <Row className="error-message">Username already taken</Row> : <div />}
            {isGenericError ? <Row className="error-message">An error has occurred, please try again later</Row> : <div />}
        </Form>
    )
}