import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap'
import { displayFetch } from '../../../api/fetch'
import { CheckboxInput } from './commons/CheckboxInput'
import { TextInput } from './commons/TextInput'
import ReactJson from 'react-json-view'
import { imagotagConfig } from '../../../utils/Constants';
import { NumberInput } from './commons/NumberInput';

export const AddImago = (props) => {

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [order, setOrder] = useState(0)
    const [visibleDashboard, setVisibleDashboard] = useState(false)
    const [isFormError, setIsFormError] = useState(false)
    const [template, setTemplate] = useState({ imagotagConfig })
    const [foodGroupId, setFoodGroupId] = useState("Select a FG")

    const postDisplay = (async (display) => {
        const method = "POST"
        const body = JSON.stringify(display)
        const headers = {
            'Content-Type': 'application/json',
        }
        await displayFetch({ method, body, headers }).then(() => { props.destroyForm() });
    })

    const handleSubmit = () => {

        if (!(name) || foodGroupId === "Select a FG") {
            setIsFormError(true)
            return
        }
        setIsFormError(false)

        console.log(template)

        let display = {
            name: name,
            description: description,
            order: parseInt(order),
            kind: "IMAGOTAG",
            place_id: parseInt(props.parent.id.split('-')[1]),
            visible_dashboard: visibleDashboard,
            imagotag_config: template.imagotagConfig
        }

        let displayWithRel = {
            display: display,
            food_group_id: parseInt(foodGroupId)
        }

        postDisplay(displayWithRel)
    }

    return (
        <Form className="pb-5">
            <Row className="first-row">
                <Col>
                    <Form.Label>KIND</Form.Label>
                    <Form.Select aria-label="Default select example" value={props.formKind} onChange={(e) => props.setFormKind(e.target.value)}>
                        <option value="DISPLAYEDITOR">DISPLAYEDITOR</option>
                        <option value="IMAGOTAG">IMAGOTAG</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col xs={12} md={6}>
                    <Form.Label>*Name</Form.Label>
                    <TextInput value={name} setValue={setName} />
                </Col>
                <Col xs={12} md={6}>
                    <Form.Label>Description</Form.Label>
                    <TextInput value={description} setValue={setDescription} />
                </Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col xs={12}>
                    <Form.Label>Imagotag Config</Form.Label>
                    <ReactJson src={template} theme="monokai" onAdd={(template) => setTemplate(template.updated_src)} onEdit={(template) => setTemplate(template.updated_src)} onDelete={(template) => setTemplate(template.updated_src)} />
                    <p className="font-size-12">Templates: 2.6.xsl, 2.6-round.xsl, 4.2-round.xsl, 12.2.xsl, 12.2-round.xsl</p>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={2}>
                    <Form.Label>Order</Form.Label>
                    <NumberInput value={order} setValue={setOrder} />
                </Col>
                <Col xs={6} md={3} className="visible-dashboard">
                    <CheckboxInput label="Visible dashboard" value={visibleDashboard} setValue={setVisibleDashboard} />
                </Col>
                <Col xs={6} md={5}>
                    <Form.Label>*Food group</Form.Label>
                    <Form.Select value={foodGroupId} onChange={(e) => setFoodGroupId(e.target.value)}>
                        <option value="Select a FG">Select a FG</option>
                        {props.foodGroups.map(foodGroup =>
                            <option value={foodGroup.id}>{foodGroup.name}</option>
                        )}
                    </Form.Select>
                </Col>
            </Row>
            <Row className="justify-content-end submit-button" >
                <Col xs={2} className="text-end">
                    <Button className="bg-dark-blue" onClick={() => handleSubmit()}>Save</Button>
                </Col>
            </Row>
            {isFormError ? <Row className="error-message">Fill the inputs marked with *</Row> : <div />}
        </Form>
    )
}