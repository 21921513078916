import { fetchData } from "./models";

export async function fetchTreeData(params) {
    return await fetchData("/" + params.fetchWhat + "/" + params.id, params);
}

export async function addCompany(params) {
    return await fetchData("/company", params)
}
export async function editCompany(params) {
    return await fetchData("/company", params)
}

export async function addContract(params) {
    return await fetchData("/contract", params)
}
export async function editContract(params) {
    return await fetchData("/contract", params)
}

export async function addSite(params) {
    return await fetchData("/site", params)
}
export async function editSite(params) {
    return await fetchData("/site", params)
}

export async function getLabels(params) {
    return await fetchData("/labels/" + params.id, params)
}
export async function labelFetch(params) {
    return await fetchData("/label", params)
}

export async function upload(params) {
    return await fetchData("/upload", params)
}

export async function fetchGeolocation(params) {
    return await fetchData("/geolocation?address=" + params.coordinatesAddress, params)
}

export async function fetchRestaurant(params) {
    return await fetchData("/restaurant", params)
}

export async function getDisplays(params) {
    return await fetchData("/displays/" + params.id, params)
}

export async function displayFetch(params) {
    return await fetchData("/display", params)
}

export async function getFoodGroups(params) {
    return await fetchData("/foodGroups/" + params.id, params)
}

export async function foodGroupFetch(params) {
    return await fetchData("/foodgroup", params)
}

export async function getCategories(params) {
    return await fetchData("/categories/" + params.id , params)
}
export async function categoryFetch(params) {
    return await fetchData("/category", params)
}

export async function checkHwidUniqness(params) {
    return await fetchData("/device_unique/" + params.hwid, params)
}

export async function displayDeviceFetch(params) {
    return await fetchData("/displaydevices?hwid=" + params.hwidToDelete + "&display_id=" + params.display_id, params)
}

export async function deviceFetch(params) {
    return await fetchData("/device", params)
}

export async function getSensors(params) {
    return await fetchData("/sensors", params)
}
export async function sensorFetch(params) {
    return await fetchData("/sensor", params)
}

export async function sensorDetailFetch(params) {
    return await fetchData("/sensor_detail", params)
}

export async function getRegions(params) {
    return await fetchData("/regions/" + params.id, params)
}

export async function regionFetch(params) {
    return await fetchData("/region", params)
}

export async function getSensorsOfRegion(params) {
    return await fetchData("/sensors/" + params.id, params)
}

export async function getCompanyUsers(params) {
    return await fetchData("/companyusers/" + params.id, params)
}

export async function getSiteUsers(params) {
    return await fetchData("/siteusers/" + params.id, params)
}

export async function userFetch(params) {
    return await fetchData("/user", params)
}