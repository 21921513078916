import React from 'react';
import { Alert } from 'react-bootstrap';

export const AlertSingle = (props) => {
  
    if (props.show) {
      return (
        <Alert variant="danger" onClose={() => props.setShow(false)} dismissible>
          <Alert.Heading>Error</Alert.Heading>
          <p>
           {props.message}
          </p>
        </Alert>
      );
    }
    return <></>;
  }
  