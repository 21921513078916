import React, { useState } from 'react';
import { Row, Col, Button, Form, Table } from 'react-bootstrap'
import { TextInput } from './commons/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { sensorFetch } from '../../../api/fetch';

export const AddSensor = (props) => {

    const [sensorId, setSensorId] = useState("")
    const [type, setType] = useState("EURECAM")
    const [sensorDetailKind, setSensorDetailKind] = useState("AREA")
    const [sensorDetailData, setSensorDetailData] = useState("")
    const [sensorDetails, setSensorDetails] = useState([])
    const [showDuplicateDataError, setShowDuplicateDataError] = useState(false)
    const [isFormError, setIsFormError] = useState(false)
    const [render, setRender] = useState(false)

    const addSensorDetail = () => {

        if (!sensorDetailData) {
            setIsFormError(true)
            return
        }

        let theSensorDetail = {
            kind: sensorDetailKind,
            detail_data: sensorDetailData
        }
        let temp = sensorDetails

        if (temp.find(element => element.detail_data === sensorDetailData) != undefined) {
            setShowDuplicateDataError(true)
            return
        }

        temp.push(theSensorDetail)
        setSensorDetails(temp)
        setSensorDetailData("")
        setRender(!render)
    }

    const handleSubmit = () => {

        if (!sensorId) {
            setIsFormError(true)
            return
        }

        let sensor = {
            sensor_id: sensorId,
            type: type,
            kind: "PRESENCE"
        }

        let sensorWithRel = {
            sensor: sensor,
            sensor_details: sensorDetails
        }

        postSensor(sensorWithRel)
    }

    const postSensor = (async (sensor) => {

        const method = "POST"
        const body = JSON.stringify(sensor)
        const headers = {
            'Content-Type': 'application/json',
        }
        await sensorFetch({ method, body, headers }).then(() => { props.destroyForm() });
    })

    const handleRemoveDetail = (detailToDelete) => {
        setSensorDetails(currentDetails => currentDetails.filter((theDetail) => theDetail !== detailToDelete));
    }

    return (
        <Form className="pt-4">
            <Row>
                <Col>
                    <Form.Label>Sensor ID</Form.Label>
                    <TextInput value={sensorId} setValue={setSensorId} />
                </Col>
                <Col>
                    <Form.Label>Type</Form.Label>
                    <Form.Select aria-label="Default select example" value={type} onChange={(e) => setType(e.target.value)}>
                        <option value="EURECAM">EURECAM</option>
                        <option value="IRLYNX">IRLYNX</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row>
                <Row className="pb-2 pt-4">
                    <Col>
                        <h4 className="font-size-18">Sensor Details:</h4>
                    </Col>
                </Row>
                <div id="display-table" className="scrollable-small">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Kind</th>
                                <th>Sensor Detail</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sensorDetails ?
                                <>
                                    {sensorDetails.map(detail => (
                                        <tr key={Math.random()}>
                                            <td>{detail.kind}</td>
                                            <td>{detail.detail_data}</td>
                                            <td><FontAwesomeIcon className="c-black me-2 button" icon={faTrash} onClick={() => handleRemoveDetail(detail)} /></td>
                                        </tr>
                                    ))}
                                </>
                                : null}
                        </tbody>
                    </Table>
                </div>
                <Row>
                    <h4 className="font-size-18">Add Sensor detail</h4>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>Type</Form.Label>
                        <Form.Select aria-label="Default select example" value={sensorDetailKind} onChange={(e) => setSensorDetailKind(e.target.value)}>
                            <option value="AREA">AREA</option>
                            <option value="LINE">LINE</option>
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Label>Data</Form.Label>
                        <div className="d-flex flex-row align-items-center">
                            <TextInput value={sensorDetailData} setValue={setSensorDetailData} />
                            <FontAwesomeIcon className="c-green mx-3 button" icon={faPlus} onClick={() => addSensorDetail()} />
                        </div>
                    </Col>
                </Row>
            </Row>
            <Row className="text-bottom justify-content-end mt-2" >
                <Col xs={2} className="text-end">
                    <Button className="bg-dark-blue" onClick={() => handleSubmit()}>Save</Button>
                </Col>
            </Row>
            {showDuplicateDataError ? <Row className="error-message">Sensor detail data already exist</Row> : <div />}
            {isFormError ? <Row className="error-message">Fill the inputs marked with *</Row> : <div />}
        </Form>
    )
}