import React, { useState } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { TextInput } from './commons/TextInput'
import { CheckboxInput } from './commons/CheckboxInput'
import { addSite } from '../../../api/fetch'

export const AddSite = (props) => {

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [city, setCity] = useState("")
    const [currency, setCurrency] = useState("EUR")
    const [useCategory, setUseCategory] = useState(false)
    const [shareFoodItems, setShareFoodItems] = useState(false)
    const [isFormError, setIsFormError] = useState(false)

    const handleCurrencyChange = (e) => {
        setCurrency(e.target.value)
    }

    const handleSubmit = () => {

        if(!(name && city)) {
            setIsFormError(true)
            return
        }
        setIsFormError(false)

        let siteRequest = {
            contract_id: parseInt(props.parent.id.split('-')[1]),
            name: name,
            description: description,
            share_food_items: shareFoodItems,
            config: {
                city: city,
                currency: currency,
                useCategory: useCategory,
            }
        }

        postData(siteRequest)
    }

    const postData = (async (siteRequest) => {
        const method = 'POST'
        const body = JSON.stringify(siteRequest)
        const headers = {
            'Content-Type': 'application/json',
        }
        await addSite({ method, body, headers }).then(() => {props.showSuccessForm()});
    })

    return (
        <Container>
            <Row className="title">
                <Col>
                    <h2>{props.parent.name + " > Add Site"}</h2>
                </Col>
            </Row>
            <Row className="name-description">
                <Col>
                    <Form.Label>*Name</Form.Label>
                    <TextInput value={name} setValue={setName} />
                </Col>
                <Col>
                    <Form.Label>Description</Form.Label>
                    <TextInput value={description} setValue={setDescription} />
                </Col>
            </Row>
            <Row className="sutitle-template">
                <h3>Config:</h3>
            </Row>
            <Row className="close-inputs">
                <Col xs={4}>
                    <Form.Label>*city:</Form.Label>
                </Col>
                <Col xs={8}>
                    <TextInput value={city} setValue={setCity} />
                </Col>
            </Row>
            <Row className="close-inputs">
                <Col xs={4}>
                    <Form.Label>currency:</Form.Label>
                </Col>
                <Col xs={8}>
                    <Form.Select value={currency} onChange={(e) => handleCurrencyChange(e)}>
                        <option value="EUR">EUR</option>
                        <option value="PTS">PTS</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row className="close-inputs">
                <Col xs={4}>
                    <Form.Label>useCategory</Form.Label>
                </Col>
                <Col xs={8}>
                    <CheckboxInput value={useCategory} setValue={setUseCategory} />
                </Col>
            </Row>
            <Row className="close-inputs">
                <Col xs={4}>
                    <Form.Label>share_food_items</Form.Label>
                </Col>
                <Col xs={8}>
                    <CheckboxInput value={shareFoodItems} setValue={setShareFoodItems} />
                </Col>
            </Row>
            <Row className="justify-content-end submit-button" >
                <Col xs={2} className="text-end">
                    <Button className="bg-dark-blue" onClick={() => handleSubmit()}>Save</Button>
                </Col>
            </Row>
            {isFormError ? <Row className="error-message">Fill the inputs marked with *</Row> : <div />}
        </Container>
    )

}