import React, { useState, useEffect } from 'react';
import { getCompanyUsers, getSiteUsers, userFetch } from '../../api/fetch';
import { Container, Row, Col, Table, Image, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { AlertModal } from './forms/commons/AlertModal'
import { AddUser } from './forms/AddUser';
import { EditUser } from './forms/EditUser';
import { AddSiteUser } from './forms/AddSiteUser';
import { EditSiteUser } from './forms/EditSiteUser';

export const Users = (props) => {

    const [users, setUsers] = useState([])
    const [userToDelete, setUserToDelete] = useState({})
    const [selectedUser, setSelectedUser] = useState({})
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showAddForm, setShowAddForm] = useState(false)
    const [showEditForm, setShowEditForm] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        console.log(props.parent.id)
        if (!loading) {
            fetchUsers(props.parent.id.split('-')[1])
        }
    }, [props.parent])

    const fetchUsers = (async (id) => {
        setLoading(true)
        setUsers([])
        const method = 'GET'
        let result = null
        if (props.parent.id.split('-')[0] === "companies") {
            result = await getCompanyUsers({ method, id });
        }
        else if (props.parent.id.split('-')[0] === "sites") {
            result = await getSiteUsers({ method, id });
        }

        if (result) setUsers(result)
        setLoading(false)
    })

    const deleteUser = (async (user) => {
        setLoading(true)
        const method = "DELETE"
        const body = JSON.stringify(user)
        const headers = {
            'Content-Type': 'application/json',
        }
        await userFetch({ method, body, headers });
        fetchUsers(props.parent.id.split('-')[1])
    })

    const handleDelete = (user) => {
        setUserToDelete(user)
        setShowDeleteModal(true)
    }

    const handleAddUser = () => {
        setShowEditForm(false)
        setShowAddForm(true)
    }

    const editUser = (user) => {
        setShowAddForm(false)
        setSelectedUser(user)
        setShowEditForm(true)
    }

    const handleDestroyForms = () => {
        setShowAddForm(false)
        setShowEditForm(false)
        fetchUsers(props.parent.id.split('-')[1])
    }

    return (
        <Container>
            {showDeleteModal ?
                <Row>
                    <Col>
                        <AlertModal message="Are you sure you want to delete?" primaryAction={deleteUser} show={showDeleteModal} setShow={setShowDeleteModal} element={userToDelete} />
                    </Col>
                </Row>
                : <div />
            }
            <Row className="title">
                <Col>
                    <h2>{props.parent.name + " > Users"}</h2>
                </Col>
            </Row>
            {loading ? <div>Loading...</div> :
                <div id="labels-table" className="scrollable">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <>
                                {users.map(user => (
                                    <tr key={user.id}>
                                        <td>{user.login}</td>
                                        <td><FontAwesomeIcon className="c-black me-2 button" icon={faEdit} onClick={() => editUser(user)} /></td>
                                        <td><FontAwesomeIcon className="c-black me-2 button" icon={faTrash} onClick={() => handleDelete(user)} /></td>
                                    </tr>
                                ))}
                            </>
                        </tbody>
                    </Table>
                </div>
            }
            {users.length === 0 ? <Row className="no-label-text"><Col className="text-center font-size-20">No users</Col></Row> : <div />}
            <Button className="bg-dark-blue mt-2" variant="primary" type="button" onClick={() => handleAddUser()}>
                Add User
            </Button>
            {props.parent.id.split('-')[0] === "companies" ?
                <>
                    {showAddForm ? <AddUser parent={props.parent} destroyForm={handleDestroyForms} /> : <div />}
                    {showEditForm ? <EditUser parent={props.parent} elementSelected={selectedUser} destroyForm={handleDestroyForms} /> : <div />}
                </>
                :
                <>
                    {showAddForm ? <AddSiteUser parent={props.parent} destroyForm={handleDestroyForms} /> : <div />}
                    {showEditForm ? <EditSiteUser parent={props.parent} elementSelected={selectedUser} destroyForm={handleDestroyForms} /> : <div />}

                </>
            }
        </Container>
    )



}