import { SidebarComponent, TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap'
import { fetchTreeData } from '../../api/fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faFileContract, faSitemap, faTags, faUtensils, faTv, faHamburger, faMapSigns, faUser, faFolderOpen } from '@fortawesome/free-solid-svg-icons'

import './sidebar.css'

export const Sidebar = (props) => {
    const [loading, setLoading] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0)

    const fields = { dataSource: props.data, id: 'id', text: 'name', child: 'subChild' }
    let sidebarObj = Sidebar

    const fetchSidebarData = (async (fetchWhat, id) => {
        if (!(fetchWhat === 'companies' || fetchWhat === 'contracts' || fetchWhat === 'sites' || fetchWhat === 'restaurants')) {
            return
        }
        setLoading(true)
        const method = 'POST'
        const body = JSON.stringify(props.data[0])
        const headers = {
            'Content-Type': 'application/json',
        }
        const result = await fetchTreeData({ method, body, headers, fetchWhat, id });
        props.setData(result)
        setLoading(false)
        props.setOperationCompleted(false)
        document.getElementById("default-sidebar").scrollTop = scrollPosition
    })

    const onNodeExpanded = ((data) => {
        console.log("ciao")
        setScrollPosition(document.getElementById("default-sidebar").scrollTop)
        fetchSidebarData(data.nodeData.text.toLowerCase(), data.nodeData.id.split('-')[1])
    })

    const onNodeSelected = useCallback((data) => {
        props.setNodeSelected(data.nodeData)
    }, [props.data])

    const toggleClick = () => {
        sidebarObj.toggle();
    }

    useEffect(() => {
        fetchSidebarData("companies", 0)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (props.operationCompleted) {
            if (props.elementSelected.id === "companies") {
                fetchSidebarData("companies", 0)
            }
            else if (props.elementSelected.id.split('-').length === 3) {
                fetchSidebarData(props.elementSelected.id.split("-")[2], props.nodeSelected.id.split("-")[1])
            }
            else {
                fetchSidebarData(props.elementSelected.id.split("-")[0], props.nodeSelected.parentID.split("-")[1])
            }
        }
    }, [props.operationCompleted])

    const nodeTemplate = useCallback((data) => {
        if (data.isAddable) {
            return (
                <div className="templateNode d-flex align-items-center">
                    <FontAwesomeIcon className="c-white me-2" icon={selectIcon(data)} />
                    <h3 className='mb-0 font-weight-bold c-white font-size-16 align-items-center'>{data.name}</h3>
                    <ButtonComponent className='ms-2 e-btn small e-round' iconCss='e-icons e-plus-icon' isPrimary={true} />
                </div>
            );
        }
        else if (data.name === "Displays" || data.name === "Labels" || data.name === "Food Groups" || data.name === "Regions" || data.name === "Users" || data.name === "Categories") {
            return (
                <div className="templateNode d-flex align-items-center">
                    <FontAwesomeIcon className="c-white me-2" icon={selectIcon(data)} />
                    <h3 className='mb-0 font-weight-bold c-white font-size-16 align-items-center'>{data.name}</h3>
                </div>
            )
        }
        return (
            <div className="templateNode d-flex align-items-center">
                <h3 className='mb-0 font-weight-regular c-white font-size-15 align-items-center'>{data.name}</h3>
            </div>
        )
    }, [props.data])

    const selectIcon = (data) => {
        switch (data.name) {
            case "Companies":
                return faBuilding
            case "Contracts":
                return faFileContract
            case "Sites":
                return faSitemap
            case "Labels":
                return faTags
            case "Restaurants":
                return faUtensils
            case "Displays":
                return faTv
            case "Food Groups":
                return faHamburger
            case "Regions":
                return faMapSigns
            case "Users":
                return faUser
            case "Categories":
                return faFolderOpen
            default:
                return
        }
    }

    return (
        <>
            <SidebarComponent id="default-sidebar" ref={Sidebar => sidebarObj = Sidebar}>
            <Button className="bg-dark-blue" onClick={() => props.setToolbarElementSelected("DEVICE")}>Add Device</Button>
            <Button className="bg-dark-blue" onClick={() => props.setToolbarElementSelected("SENSOR")}>Sensors</Button>
                {loading ? <div className="c-white sidebar-loading">Loading...</div> :
                    <TreeViewComponent fields={fields} nodeExpanded={onNodeExpanded} nodeSelected={onNodeSelected} nodeTemplate={nodeTemplate} />
                }
            </SidebarComponent>
            <div>
                <ButtonComponent
                    onClick={toggleClick}
                    className="e-btn"
                > {"<-->"}
                </ButtonComponent>
            </div>
        </>
    )
}