import React, { useState, useEffect } from 'react';
import { getSensors, sensorFetch } from '../../api/fetch';
import { Container, Row, Col, Table, Image, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { AlertModal } from './forms/commons/AlertModal'
import { AddSensor } from './forms/AddSensor';
import { EditSensor } from './forms/EditSensor';

export const Sensors = () => {

    const [sensors, setSensors] = useState([])
    const [sensorToDelete, setSensorToDelete] = useState({})
    const [loading, setLoading] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [sensorSelected, setSensorSelected] = useState({})
    const [showAddForm, setShowAddForm] = useState(false)
    const [showEditForm, setShowEditForm] = useState(false)
    const [showDeleteErrorMessage, setShowDeleteErrorMessage] = useState(false)


    useEffect(() => {
        setShowDeleteErrorMessage(false)
        if (!loading) {
            fetchSensors()
        }
    }, [sensorSelected])

    const fetchSensors = (async () => {
        setLoading(true)
        setSensors([])
        const method = 'GET'
        const result = await getSensors({ method });
        if (result) setSensors(result)
        setLoading(false)
    })

    const editSensor = (sensor) => {
        setShowAddForm(false)
        setSensorSelected(sensor)
        setShowEditForm(true)
    }
    const handleDelete = (sensor) => {
        setSensorToDelete(sensor)
        setShowDeleteModal(true)
    }
    const deleteSensor = (async () => {
        setLoading(true)
        const method = "DELETE"
        const body = JSON.stringify(sensorToDelete)
        const headers = {
            'Content-Type': 'application/json',
        }
        const result = await sensorFetch({ method, body, headers }).then((result => {
            setLoading(false)
            setShowDeleteErrorMessage(false)
            if (result === "DELETE_NOT_PERMITTED") {
                setShowDeleteErrorMessage(true)
            }
            else {
                fetchSensors()
            }
        }))
    })
    const handleAddSensor = () => {
        setShowAddForm(true)
    }

    const handleDestroyForms = () => {
        setShowDeleteErrorMessage(false)
        setShowAddForm(false)
        setShowEditForm(false)
        if (!loading) fetchSensors()
    }

    return (
        <Container>
            {showDeleteModal ?
                <Row>
                    <Col>
                        <AlertModal message="Are you sure you want to delete?" primaryAction={deleteSensor} show={showDeleteModal} setShow={setShowDeleteModal} element={sensorToDelete} />
                    </Col>
                </Row>
                : <div />
            }
            <Row className="title">
                <Col>
                    <h2>Sensors</h2>
                </Col>
            </Row>
            {loading ? <div>Loading...</div> :
                <div id="labels-table" className="scrollable">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>Sensor ID</th>
                                <th>Type</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <>
                                {sensors.map(sensor => (
                                    <tr key={sensor.sensor.id}>
                                        <td>{sensor.sensor.sensor_id}</td>
                                        <td>{sensor.sensor.type}</td>
                                        <td><FontAwesomeIcon className="c-black me-2 button" icon={faEdit} onClick={() => editSensor(sensor)} /></td>
                                        <td><FontAwesomeIcon className="c-black me-2 button" icon={faTrash} onClick={() => handleDelete(sensor.sensor)} /></td>
                                    </tr>
                                ))}
                            </>
                        </tbody>
                    </Table>
                </div>
            }
            {sensors.length === 0 ? <Row className="no-label-text"><Col className="text-center font-size-20">No sensors</Col></Row> : <div />}
            <Button className="bg-dark-blue mt-2" variant="primary" type="button" onClick={() => handleAddSensor()}>
                Add Sensor
            </Button>
            {showAddForm ? <AddSensor destroyForm={handleDestroyForms} /> : <div />}
            {showEditForm ? <EditSensor elementSelected={sensorSelected} destroyForm={handleDestroyForms} /> : <div />}
            {showDeleteErrorMessage ? <Row className="error-message">Sensor cannot be deleted because data already exist for it</Row> : <div />}
        </Container>
    )
}