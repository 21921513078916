import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Tooltip, Container } from 'react-bootstrap'
import { categoryFetch, foodGroupFetch } from '../../../api/fetch'
import { CheckboxInput } from './commons/CheckboxInput';
import { NumberInput } from './commons/NumberInput';
import { SvgUploader } from './commons/SvgUploader';
import { TextInput } from './commons/TextInput'
import { TooltipComponent } from "./commons/TooltipComponent";
import { Uploader } from "./commons/Uploader";


export const EditFoodGroup = (props) => {

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [picto, setPicto] = useState("")
    const [order, setOrder] = useState(0)
    const [iconPicto, setIconPicto] = useState("")
    const [coverPicto, setCoverPicto] = useState("")
    const [showInMobile, setShowInMobile] = useState(false)
    const [tagId, setTagId] = useState(null)
    const [newCategoryName, setNewCategoryName] = useState("")
    const [categoryImage, setCategoryImage] = useState("")
    const [categoryOrder, setCategoryOrder] = useState(0)
    const [categoryCoverImage, setCategoryCoverImage] = useState("")

    const [isFormError, setIsFormError] = useState(false)

    useEffect(() => {
        console.log(props.categories)
        setName(props.selectedFoodGroup.name)
        setDescription(props.selectedFoodGroup.description)
        setPicto(props.selectedFoodGroup.picto)
        setOrder(props.selectedFoodGroup.order)
        setIconPicto(props.selectedFoodGroup.icon_picto)
        setCoverPicto(props.selectedFoodGroup.cover_picto)
        setShowInMobile(props.selectedFoodGroup.show_in_mobile)

        if (props.selectedFoodGroup.tag_id) {
            setTagId(props.selectedFoodGroup.tag_id)
            //popolare qui i valori della categoria per renderla modificabile
        }
        else {
            setTagId(-2)
        }



    }, [props.selectedFoodGroup])

    const handleSubmit = () => {

        if (!(name)) {
            setIsFormError(true)
            return
        }
        if (tagId == -1) {
            if (!(categoryImage && categoryCoverImage && newCategoryName)) {
                setIsFormError(true)
                return
            }
        }
        setIsFormError(false)

        let thePicto = picto
        let theIconPicto = iconPicto
        let theCoverPicto = coverPicto

        if (!thePicto) {
            thePicto = props.selectedFoodGroup.picto
        }
        if (!theIconPicto) {
            theIconPicto = props.selectedFoodGroup.icon_picto
        }
        if (!theCoverPicto) {
            theCoverPicto = props.selectedFoodGroup.cover_picto
        }

        let foodGroup = props.selectedFoodGroup

        foodGroup.name = name
        foodGroup.description = description
        foodGroup.picto = thePicto
        foodGroup.order = parseInt(order)
        foodGroup.icon_picto = theIconPicto
        foodGroup.cover_picto = theCoverPicto
        foodGroup.show_in_mobile = showInMobile
        if (tagId == -2) {
            foodGroup.tag_id = null
        }
        else {
            foodGroup.tag_id = parseInt(tagId)
        }

        let tag = {
            name: newCategoryName,
            image: categoryImage,
            order: parseInt(categoryOrder),
            cover_image: categoryCoverImage,
            kind: "CATEGORYFOODGROUP",
            place_id: parseInt(props.selectedFoodGroup.place_id)
        }

        if (tagId == -1) {
            postCategory(tag, foodGroup)
        }
        else {
            postFoodGroup(foodGroup)
        }
    }

    const postCategory = (async (tag, foodGroup) => {
        const method = "POST"
        const body = JSON.stringify(tag)
        const headers = {
            'Content-Type': 'application/json',
        }
        const result = await categoryFetch({ method, body, headers }).then((result) => {
            foodGroup.tag_id = result.id
            postFoodGroup(foodGroup)
                .then(() => { props.destroyForm() })
        })
    })

    const postFoodGroup = (async (foodGroup) => {
        const method = "PUT"
        const body = JSON.stringify(foodGroup)
        const headers = {
            'Content-Type': 'application/json',
        }
        await foodGroupFetch({ method, body, headers }).then(() => { props.destroyForm() });
    })

    return (
        <Form className="pb-5">
            <Row className="pt-2 pb-4">
                <Col xs={12} md={6}>
                    <Form.Label>*Name</Form.Label>
                    <TextInput value={name} setValue={setName} />
                </Col>
                <Col xs={12} md={6}>
                    <Form.Label>Description</Form.Label>
                    <TextInput value={description} setValue={setDescription} />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4}>
                    <Form.Label>Picto</Form.Label>
                    <SvgUploader setValue={setPicto} value={picto} />
                </Col>
                <Col xs={6} md={2}>
                    <Form.Label>Order</Form.Label>
                    <NumberInput value={order} setValue={setOrder} />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={5}>
                    <div className="d-flex align-items-baseline">
                        <Form.Label>Icon picto</Form.Label>
                        <TooltipComponent />
                    </div>
                    <Uploader setValue={setIconPicto} value={iconPicto} />
                </Col>
                <Col xs={12} md={5}>
                    <div className="d-flex align-items-baseline">
                        <Form.Label>Cover picto</Form.Label>
                        <TooltipComponent />
                    </div>
                    <Uploader setValue={setCoverPicto} value={coverPicto} />
                </Col>
            </Row>
            <Row className="pt-2">
                <Col xs={4} md={3}>
                    <Form.Label>Category</Form.Label>
                    <Form.Select value={tagId} onChange={(e) => setTagId(e.target.value)}>
                        <option value={-2}>No category</option>
                        <option value={-1}>New Category</option>
                        {props.categories.map(category =>
                            <option value={category.id}>{category.name}</option>
                        )}
                    </Form.Select>
                </Col>
                <Col xs={6} md={3} className="align-end">
                    <CheckboxInput label="show_in_mobile" value={showInMobile} setValue={setShowInMobile} />
                </Col>
            </Row>
            {tagId == -1 ?
                <>
                    <Row className="pt-4">
                        <Col xs={6} md={6}>
                            <Form.Label>*Category name</Form.Label>
                            <TextInput value={newCategoryName} setValue={setNewCategoryName} />
                        </Col>
                        <Col xs={6} md={2}>
                            <Form.Label>*Cateogry order</Form.Label>
                            <NumberInput value={categoryOrder} setValue={setCategoryOrder} />
                        </Col>
                    </Row>
                    <Row className="pt-3">
                        <Col xs={6} md={6}>
                            <div className="d-flex align-items-baseline">
                                <Form.Label>*Category cover_image</Form.Label>
                                <TooltipComponent />
                            </div>
                            <Uploader value={categoryCoverImage} setValue={setCategoryCoverImage} />
                        </Col>
                        <Col xs={6} md={6}>
                            <div className="d-flex align-items-baseline">
                                <Form.Label>*Category image</Form.Label>
                                <TooltipComponent />
                            </div>
                            <Uploader value={categoryImage} setValue={setCategoryImage} />
                        </Col>
                    </Row>
                </>
                : <span />
            }
            <Row className="justify-content-end submit-button" >
                <Col xs={2} className="text-end">
                    <Button className="bg-dark-blue" onClick={() => handleSubmit()}>Apply</Button>
                </Col>
            </Row>
            {isFormError ? <Row className="error-message">Fill the inputs marked with *</Row> : <div />}
        </Form>
    )
}