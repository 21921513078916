import React from 'react'
import { Form } from 'react-bootstrap'
import { upload } from '../../../../api/fetch'


export const Uploader = (props) => {

    const handleChangeImage = (e) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
            if (reader.readyState === 2) {
                uploadFile(e.target.files[0]);
            }
        };
    }

    const uploadFile = (async (imageFile) => {
        const method = "POST"
        const formData = new FormData();
        formData.append('file', imageFile);
        const body = formData
        const result = await upload({ method, body });
        props.setValue(result.location)
    })

    return (
        <div>
            <Form.Control type="file" onChange={(e) => handleChangeImage(e)} accept="image/png, image/jpeg" />
            {props.value ? <a className="font-size-12 ml-2" href={props.value} target="_blank">Show image</a> : <span/>}
        </div>
    )
}