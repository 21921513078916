export function getObjectFromNestedArrayByID(object, theID) {
    if (object != null) {
        if (object["id"] == theID) {
            return object;
        }
    }
    for (var i = 0; i < Object.keys(object).length; i++) {
        if (typeof object[Object.keys(object)[i]] == "object") {
            if (object[Object.keys(object)[i]] != null) {
                var o = getObjectFromNestedArrayByID(object[Object.keys(object)[i]], theID);
                if (o != null)
                    return o;
            }
        }
    }
    return null;
}

export function generateRandom() {
    return (+new Date).toString(36);
}

export function generateRandomNumber() {
    return new Date().valueOf().toString().substring(7)
}