import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { TextInput } from './commons/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faDiceSix } from '@fortawesome/free-solid-svg-icons'
import { checkHwidUniqness, deviceFetch } from '../../../api/fetch';
import { Redirect } from 'react-router';
import { generateRandom } from '../../../utils/Functions';
import { propTypes } from 'react-bootstrap/esm/Image';

export const AddDevice = (props) => {

    const [hwid, setHwid] = useState("")
    const [isFormError, setIsFormError] = useState(false)
    const [isFetchError, setIsFetchError] = useState(false)
    const [hwidAlreadyExistError, setHwidAlreadyExistError] = useState(false)

    const handleSubmit = () => {

        setHwidAlreadyExistError(false)

        if (!hwid) {
            setIsFormError(true)
        }
        setIsFormError(false)

        let device = {
            hwid: hwid
        }

        postDevice(device)
    }

    const postDevice = (async (device) => {
        setIsFetchError(false)
        const method = 'POST'
        const method2 = 'GET'
        const body = JSON.stringify(device)
        const headers = {
            'Content-Type': 'application/json',
        }
        let result = await checkHwidUniqness({ method2, headers, hwid })

        if (!result) {
            const result = await deviceFetch({ method, body, headers }).then((result => {
                if (!result) {
                    setIsFetchError(true)
                }
                else {
                    props.showSuccessToolbar()
                }
            }))
        }
        else {
            setHwidAlreadyExistError(true)
        }

    })

    return (
        <Container>
            <Row>
                <Col xs={12} className="text-center">
                    <h2>Add Device</h2>
                </Col>
            </Row>
            <Row className="name-description" >
                <div className="d-flex flex-row text-center align-items-center">
                    <Col xs={12} >
                        <Form.Label>*Hwid</Form.Label>
                        <div className="d-flex flex-row align-items-center">
                            <TextInput value={hwid} setValue={setHwid} />
                            <FontAwesomeIcon className="c-black mx-3 button" icon={faDiceSix} onClick={() => setHwid(generateRandom())} />
                        </div>
                    </Col>
                </div>
            </Row>
            <Row className="justify-content-end submit-button" >
                <Col className="text-end">
                    <Button className="bg-dark-blue" onClick={() => handleSubmit()}>Save</Button>
                </Col>
            </Row>
            {isFormError ? <Row className="error-message">Fill the inputs marked with *</Row> : <div />}
            {isFetchError ? <Row className="error-message">An error has occurred, please try again later.</Row> : <div />}
            {hwidAlreadyExistError ? <Row className="error-message">Hwid already exist.</Row> : <div />}
        </Container>
    )
}