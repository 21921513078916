import React from 'react';
import { Form } from 'react-bootstrap';

export const CheckboxInput = (props) => {

    const handleValueChange = (e) => {
        props.setValue(!props.value)
    }

    return (
        <Form.Check type="checkbox" label={props.label} checked={props.value} onChange={(e) => handleValueChange(e)} />
    )
}