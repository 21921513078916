import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap'
import { displayFetch } from '../../../api/fetch'
import { CheckboxInput } from './commons/CheckboxInput'
import { TextInput } from './commons/TextInput'
import ReactJson from 'react-json-view'
import { imagotagConfig } from '../../../utils/Constants';
import { NumberInput } from './commons/NumberInput';

export const EditImago = (props) => {

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [order, setOrder] = useState(0)
    const [visibleDashboard, setVisibleDashboard] = useState(false)
    const [isFormError, setIsFormError] = useState(false)
    const [template, setTemplate] = useState({})
    const [foodGroupId, setFoodGroupId] = useState(-1)

    useEffect(() => {
        setName(props.displaySelected.display.name)
        setDescription(props.displaySelected.display.description)
        setOrder(props.displaySelected.display.order)
        setVisibleDashboard(props.displaySelected.display.visible_dashboard)
        setTemplate(props.displaySelected.display.imagotag_config)

        if (props.displaySelected.food_group_id) {
            setFoodGroupId(props.displaySelected.food_group_id)
        }

        console.log(template)

    }, [props.displaySelected])

    const postDisplay = (async (display) => {
        const method = "PUT"
        const body = JSON.stringify(display)
        const headers = {
            'Content-Type': 'application/json',
        }
        await displayFetch({ method, body, headers }).then(() => { props.destroyForm() });
    })

    const handleSubmit = () => {

        if (!(name) || foodGroupId == -1) {
            setIsFormError(true)
            return
        }
        setIsFormError(false)

        console.log(template)

        let display = props.displaySelected.display
        display.name = name
        display.description = description
        display.order = parseInt(order)
        display.visible_dashboard = visibleDashboard
        display.imagotag_config = template

        let displayWithRel = {
            display: display,
            food_group_id: parseInt(foodGroupId)
        }

        postDisplay(displayWithRel)
    }

    return (
        <Form className="pb-5">
            <Row className="first-row">
                <Col>
                    <Form.Label>KIND</Form.Label>
                    <Form.Select aria-label="Default select example" value={props.formKind} onChange={(e) => props.setFormKind(e.target.value)} disabled>
                        <option value="DISPLAYEDITOR">DISPLAYEDITOR</option>
                        <option value="IMAGOTAG">IMAGOTAG</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col xs={12} md={6}>
                    <Form.Label>*Name</Form.Label>
                    <TextInput value={name} setValue={setName} />
                </Col>
                <Col xs={12} md={6}>
                    <Form.Label>Description</Form.Label>
                    <TextInput value={description} setValue={setDescription} />
                </Col>
            </Row>
            <Row className="pt-2 pb-2">
                <Col xs={12}>
                    <Form.Label>Imagotag Config</Form.Label>
                    <ReactJson src={template} theme="monokai" onAdd={(template) => setTemplate(template.updated_src)} onEdit={(template) => setTemplate(template.updated_src)} onDelete={(template) => setTemplate(template.updated_src)} />
                    <p className="font-size-12">Templates: 2.6.xsl, 2.6-round.xsl, 4.2-round.xsl, 12.2.xsl, 12.2-round.xsl</p>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={2}>
                    <Form.Label>Order</Form.Label>
                    <NumberInput value={order} setValue={setOrder} />
                </Col>
                <Col xs={6} md={3} className="visible-dashboard">
                    <CheckboxInput label="Visible dashboard" value={visibleDashboard} setValue={setVisibleDashboard} />
                </Col>
                <Col xs={6} md={5}>
                    <Form.Label>Food group</Form.Label>
                    <Form.Select value={foodGroupId} onChange={(e) => setFoodGroupId(e.target.value)}>
                        <option value={-1}>Select a FG</option>
                        {props.foodGroups.map(foodGroup =>
                            <option value={foodGroup.id}>{foodGroup.name}</option>
                        )}
                    </Form.Select>
                </Col>
            </Row>
            <Row className="justify-content-end submit-button" >
                <Col xs={2} className="text-end">
                    <Button className="bg-dark-blue" onClick={() => handleSubmit()}>Apply</Button>
                </Col>
            </Row>
            {isFormError ? <Row className="error-message">Fill the inputs marked with *</Row> : <div />}
        </Form>
    )
}