import React, { useState } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { TextInput } from './commons/TextInput'
import { addContract } from '../../../api/fetch'

import './forms.css'
import { Uploader } from './commons/Uploader'

export const AddContract = (props) => {

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [color, setColor] = useState("")
    const [backgroundColor, setBackgroundColor] = useState("")
    const [clientLogo, setClientLogo] = useState("")
    const [isFormError, setIsFormError] = useState(false)

    const handleSubmit = () => {
        if (!(name && clientLogo)) {
            setIsFormError(true)
            return
        }
        setIsFormError(false)

        let contractRequest = {
            companyID: props.parent.id.split('-')[1],
            contract: {
                name: name,
                description: description,
                template: {
                    color: color,
                    bg_color: backgroundColor,
                    client_logo: clientLogo,
                }
            }
        }

        postData(contractRequest)
    }

    const postData = (async (contractRequest) => {
        const method = 'POST'
        const body = JSON.stringify(contractRequest)
        const headers = {
            'Content-Type': 'application/json',
        }
        await addContract({ method, body, headers }).then(() => {props.showSuccessForm()});
    })

    return (
        <Container>
            <Row className="title">
                <Col>
                    <h2>{props.parent.name + " > Add Contract"}</h2>
                </Col>
            </Row>
            <Row className="name-description">
                <Col>
                    <Form.Label>*Name</Form.Label>
                    <TextInput value={name} setValue={setName} />
                </Col>
                <Col>
                    <Form.Label>Description</Form.Label>
                    <TextInput value={description} setValue={setDescription} />
                </Col>
            </Row>
            <Row className="sutitle-template">
                <h3>Template:</h3>
            </Row>
            <Row className="close-inputs">
                <Col xs={4}>
                    <Form.Label>color:</Form.Label>
                </Col>
                <Col xs={5}>
                    <TextInput value={color} setValue={setColor} />
                </Col>
            </Row>
            <Row className="close-inputs">
                <Col xs={4}>
                    <Form.Label>bg_color:</Form.Label>
                </Col>
                <Col xs={5}>
                    <TextInput value={backgroundColor} setValue={setBackgroundColor} />
                </Col>
            </Row>
            <Row className="close-inputs">
                <Col xs={4}>
                    <Form.Label>*client_logo:</Form.Label>
                </Col>
                <Col xs={8}>
                    <Uploader setValue={setClientLogo} value={clientLogo} />
                </Col>
            </Row>
            <Row className="justify-content-end submit-button" >
                <Col xs={2} className="text-end">
                    <Button className="bg-dark-blue" onClick={() => handleSubmit()}>Save</Button>
                </Col>
            </Row>
            {isFormError ? <Row className="error-message">Fill the inputs marked with *</Row> : <div />}
        </Container>
    )

}