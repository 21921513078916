import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap'
import { userFetch } from '../../../api/fetch';
import { TextInput } from './commons/TextInput';

export const EditUser = (props) => {

    const [login, setLogin] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [isFormError, setIsFormError] = useState(false)
    const [isUsernameAlreadyTakenError, setIsUsernameAlreadyTakenError] = useState(false)
    const [isGenericError, setIsGenericError] = useState(false)

    useEffect(() => {
        setLogin(props.elementSelected.login)
        setName(props.elementSelected.name)
        setEmail(props.elementSelected.email)
    }, [props.elementSelected])

    const handleSubmit = () => {

        if (!(name && login)) {
            setIsFormError(true)
            return
        }
        setIsFormError(false)

        let user = props.elementSelected

        user.name = name
        user.email = email
        user.login = login

        postUser(user)
    }

    const postUser = (async (user) => {
        const method = "PUT"
        const body = JSON.stringify(user)
        const headers = {
            'Content-Type': 'application/json',
        }

        const result = await userFetch({ method, body, headers }).then((result) => {
            if (result === "USERNAME_ALREADY_TAKEN") {
                setIsUsernameAlreadyTakenError(true)
                return
            }
            else if (result) {
                props.destroyForm()
            }
            else {
                setIsGenericError(true)
                return
            }
        })
    })

    return (
        <Form className="pt-4">
            <Row>
                <Col xs={12} md={6}>
                    <Form.Label>*Username</Form.Label>
                    <TextInput value={login} setValue={setLogin} />
                </Col>
            </Row>
            <Row className="pt-4">
                <Col xs={12} md={6}>
                    <Form.Label>*Name</Form.Label>
                    <TextInput value={name} setValue={setName} />
                </Col>
                <Col xs={12} md={6}>
                    <Form.Label>Email</Form.Label>
                    <TextInput value={email} setValue={setEmail} />
                </Col>
            </Row>
            <Row className="justify-content-end submit-button" >
                <Col xs={2} className="text-end">
                    <Button className="bg-dark-blue" onClick={() => handleSubmit()}>Save</Button>
                </Col>
            </Row>
            {isFormError ? <Row className="error-message">Fill the inputs marked with *</Row> : <div />}
            {isUsernameAlreadyTakenError ? <Row className="error-message">Username already taken</Row> : <div />}
            {isGenericError ? <Row className="error-message">An error has occurred, please try again later</Row> : <div />}
        </Form>
    )
}