import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { fetchGeolocation, fetchRestaurant } from '../../../api/fetch';
import { getObjectFromNestedArrayByID } from '../../../utils/Functions';
import { TextInput } from './commons/TextInput'
import { NumberInput } from './commons/NumberInput'
import { Uploader } from './commons/Uploader'
import { TooltipComponent } from './commons/TooltipComponent'

import './forms.css'

export const EditRestaurant = (props) => {

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [logo, setLogo] = useState("")
    const [isMobile, setIsMobile] = useState(false)
    const [coordinatesAddress, setCoordinatesAddress] = useState("")
    const [longitude, setLongitude] = useState("")
    const [latitude, setLatitude] = useState("")
    const [isErrorAddress, setIsErrorAddress] = useState(false)
    const [address, setAddress] = useState("")
    const [coverImage, setCoverImage] = useState("")
    const [isFormError, setIsFormError] = useState(false)
    const [contractID, setContractID] = useState(0)
    const [order, setOrder] = useState(0)

    useEffect(() => {

        const element = props.elementSelected.place

        setName(props.elementSelected.name)
        setDescription(props.elementSelected.description)
        setLogo(element.template.client_logo)
        setCoordinatesAddress("")
        setLongitude(props.elementSelected.longitude)
        setLatitude(props.elementSelected.latitude)
        setAddress(element.address)
        setCoverImage(element.picto_cover)
        setOrder(element.order)

    }, [props.elementSelected])

    //Search the parent company to set IsMobile
    useEffect(() => {
        if (!props.nodeSelected.id.includes("restaurants-")) return
        const placeParent = getObjectFromNestedArrayByID(props.data, props.nodeSelected.parentID)
        const siteParent = getObjectFromNestedArrayByID(props.data, placeParent.id.split("-")[1])
        const contractParent = getObjectFromNestedArrayByID(props.data, "contracts-" + siteParent.contract_id)
        setContractID(siteParent.contract_id)
        const companyParent = getObjectFromNestedArrayByID(props.data, contractParent.parentID)

        if (companyParent.mb_template != null) {
            setIsMobile(true)
        }
    })

    const geoLocation = (async () => {
        const method = 'GET'
        const result = await fetchGeolocation({ method, coordinatesAddress })

        if (result.results.length === 0) {
            setIsErrorAddress(true)
            return
        }
        setIsErrorAddress(false)
        setLongitude(result.results[0].geometry.location.lng)
        setLatitude(result.results[0].geometry.location.lat)
        setAddress(result.results[0].formatted_address)
    })

    const postRestaurant = (async (restaurant) => {
        const method = 'PUT'
        const body = JSON.stringify(restaurant)
        const headers = {
            'Content-Type': 'application/json',
        }

        await fetchRestaurant({ method, body, headers }).then(() => { props.showSuccessForm() })

    })

    const handleSubmit = () => {

        if (!name) {
            setIsFormError(true)
            return
        }
        if (!(longitude && latitude && address) && isMobile) {
            setIsFormError(true)
            return
        }
        setIsFormError(false)

        let theLogo = logo
        let theCoverImage = coverImage

        if (!theLogo) {
            theLogo = props.elementSelected.place.template.client_logo
        }
        if (!theCoverImage) {
            theCoverImage = props.elementSelected.place.picto_cover
        }

        let restaurantRequest = {
            restaurant: {
                id: parseInt(props.elementSelected.id.split('-')[1]),
                name: name,
                description: description,
                address: address,
                picto_cover: theCoverImage,
                kind: "RESTAURANT",
                template: {
                    client_logo: theLogo,
                },
                contract_id: contractID,
                parent_id: parseInt(props.parent.id.split('-')[1]),
                created_at: props.elementSelected.place.created_at,
                capacity: props.elementSelected.place.capacity,
                config: props.elementSelected.place.config,
                mpath: props.elementSelected.place.mpath,
                picture: props.elementSelected.place.picture,
                picto: props.elementSelected.place.picto,
                order: parseInt(order),
                features: props.elementSelected.place.features,
                share_food_items: props.elementSelected.place.share_food_items
            },
            longitude: longitude.toString(),
            latitude: latitude.toString()
        }

        if (props.elementSelected.place.template.theme) {
            restaurantRequest.restaurant.template.theme = props.elementSelected.place.template.theme
        }

        postRestaurant(restaurantRequest)
    }

    return (
        <Container>
            <Row className="title">
                <Col>
                    <h2>{props.elementSelected.name + " > Edit Restaurant"}</h2>
                </Col>
            </Row>
            <Row className="name-description">
                <Col>
                    <Form.Label>*Name</Form.Label>
                    <TextInput value={name} setValue={setName} />
                </Col>
                <Col>
                    <Form.Label>Description</Form.Label>
                    <TextInput value={description} setValue={setDescription} />
                </Col>
            </Row>
            <Row className="name-description pb-4">
                <Col xs={10}>
                    <Form.Label>Logo</Form.Label>
                    <Uploader setValue={setLogo} value={logo} />
                </Col>
                <Col xs={2}>
                    <Form.Label>Order</Form.Label>
                    <NumberInput value={order} setValue={setOrder} />
                </Col>
            </Row>
            {isMobile ?
                <div>
                    <Row>
                        <h3>Mobile:</h3>
                    </Row>
                    <div className="border border-dark rounded">
                        <Form >
                            <Row className="coordinates justify-content-center">
                                <Col xs={12} md={8}>
                                    <Form.Label>*Coordinate</Form.Label>
                                    <TextInput value={coordinatesAddress} setValue={setCoordinatesAddress} />
                                </Col>
                                <Col xs={12} md={2} className="align-self-end" >
                                    <Button onClick={() => geoLocation()} className="mt-3 bg-dark-blue">Search</Button>
                                </Col>
                            </Row>
                            {isErrorAddress ? <Row className="error-message m-auto">Address not found</Row> : <div />}
                            <Row className="pt-3 justify-content-center">
                                <Col md={5}>
                                    <div className="d-flex align-items-baseline">
                                        <Form.Label>Longitude</Form.Label>
                                        <TooltipComponent />
                                    </div>
                                    <TextInput value={longitude} setValue={setLongitude} />
                                </Col>
                                <Col md={5}>
                                    <div className="d-flex align-items-baseline">
                                        <Form.Label>Latitude</Form.Label>
                                        <TooltipComponent />
                                    </div>
                                    <TextInput value={latitude} setValue={setLatitude} />
                                </Col>
                            </Row>
                            <Row className="pt-3 justify-content-center">
                                <Col xs={12} md={10}>
                                    <div className="d-flex align-items-baseline">
                                        <Form.Label>*Address</Form.Label>
                                        <TooltipComponent />
                                    </div>
                                    <TextInput value={address} setValue={setAddress} />
                                </Col>
                            </Row>
                            <Row className="pt-3 pb-4 justify-content-center">
                                <Col xs={10}>
                                    <Form.Label>Cover Image</Form.Label>
                                    <Uploader setValue={setCoverImage} value={coverImage} />
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
                : <div />
            }
            <Row className="justify-content-end submit-button" >
                <Col className="text-end">
                    <Button className="bg-dark-blue" onClick={() => handleSubmit()}>Save</Button>
                </Col>
            </Row>
            {isFormError ? <Row className="error-message">Fill the inputs marked with *</Row> : <div />}
        </Container>
    )
}