import React, { useState } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { labelFetch } from '../../../api/fetch'
import { TextInput } from './commons/TextInput'
import { Uploader } from './commons/Uploader'

import './labelForms.css'

export const AddLabel = (props) => {

    const [name, setName] = useState("")
    const [imageUrl, setImageUrl] = useState("")
    const [isFormError, setIsFormError] = useState(false)

    const handleSubmit = () => {

        if (!(name && imageUrl)) {
            setIsFormError(true)
            return
        }

        let label = {
            name: name,
            image: imageUrl,
            kind: "LABEL",
            place_id: parseInt(props.parent.id.split('-')[1]),
            name_secondary: ""
        }

        postLabel(label)
    }

    const postLabel = (async (label) => {
        const method = 'POST'
        const body = JSON.stringify(label)
        const headers = {
            'Content-Type': 'application/json',
        }
        await labelFetch({ method, body, headers }).then(() => { props.destroyForm() });
    })

    return (
        <Form>
            <Row className="first-row">
                <Col xs={5}>
                    <Form.Label>*Name</Form.Label>
                    <TextInput value={name} setValue={setName} />
                </Col>
                <Col xs={7}>
                    <Form.Label>*Image</Form.Label>
                    <Uploader setValue={setImageUrl} value={imageUrl} />
                </Col>
            </Row>
            <Row className="justify-content-end submit-button" >
                <Col xs={2} className="text-end">
                    <Button className="bg-dark-blue" onClick={() => handleSubmit()}>Save</Button>
                </Col>
            </Row>
            {isFormError ? <Row className="error-message">Fill the inputs marked with *</Row> : <div />}
        </Form>
    )
}