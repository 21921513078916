import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap'
import { userFetch } from '../../../api/fetch';
import { TextInput } from './commons/TextInput';

export const AddUser = (props) => {

    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [isFormError, setIsFormError] = useState(false)
    const [isUsernameAlreadyTakenError, setIsUsernameAlreadyTakenError] = useState(false)
    const [isGenericError, setIsGenericError] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleSubmit = () => {

        setIsUsernameAlreadyTakenError(false)
        setIsGenericError(false)

        if (!(name && password && login)) {
            setIsFormError(true)
            return
        }
        setIsFormError(false)

        let user = {
            name: name,
            login: login,
            email: email,
            password: password,
            phone: "",
            permissions: {
                site_id: parseInt(0),
                type_user: "COMPANY_USER",
                company_id: parseInt(props.parent.id.split('-')[1])
            }
        }

        postUser(user)
    }

    const postUser = (async (user) => {
        setLoading(true)
        const method = "POST"
        const body = JSON.stringify(user)
        const headers = {
            'Content-Type': 'application/json',
        }

        const result = await userFetch({ method, body, headers }).then((result) => {
            if (result === "USERNAME_ALREADY_TAKEN") {
                setLoading(false)
                setIsUsernameAlreadyTakenError(true)
                return
            }
            else if (result) {
                setLoading(false)
                props.destroyForm()
            }
            else {
                setLoading(false)
                setIsGenericError(true)
                return
            }
        })
    })

    return (
        <>
            {loading ? <div>Loading...</div> :
                <Form className="pt-4">
                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Label>*Username</Form.Label>
                            <TextInput value={login} setValue={setLogin} />
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Label>*Password</Form.Label>
                            <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </Col>
                    </Row>
                    <Row className="pt-4">
                        <Col xs={12} md={6}>
                            <Form.Label>*Name</Form.Label>
                            <TextInput value={name} setValue={setName} />
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Label>Email</Form.Label>
                            <TextInput value={email} setValue={setEmail} />
                        </Col>
                    </Row>
                    <Row className="justify-content-end submit-button" >
                        <Col xs={2} className="text-end">
                            <Button className="bg-dark-blue" onClick={() => handleSubmit()}>Save</Button>
                        </Col>
                    </Row>
                    {isFormError ? <Row className="error-message">Fill the inputs marked with *</Row> : <div />}
                    {isUsernameAlreadyTakenError ? <Row className="error-message">Username already taken</Row> : <div />}
                    {isGenericError ? <Row className="error-message">An error has occurred, please try again later</Row> : <div />}
                </Form>
            }
        </>
    )
}