export const imagotagConfig = {
    devices: [
        {
            template: "",
            tablet_id: "",
            imagotag_url: "",
            food_items_idx: []
        }
    ]
}

export const fluidityConfig = {
    "fluidity_triggers": [
        {
            "text": "Libre",
            "color": "#93bf00",
            "image": "",
            "level": 0
        },
        {
            "text": "Service Ralenti",
            "color": "#ffb500",
            "level": 33
        },
        {
            "text": "Service Saturé",
            "color": "#f54029",
            "level": 66
        }
    ]
}