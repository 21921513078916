import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Table, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { foodGroupFetch, getFoodGroups, getCategories } from '../../api/fetch'
import { AlertModal } from './forms/commons/AlertModal'

import './foodGroups.css'
import { AddFoodGroup } from './forms/AddFoodGroup'
import { EditFoodGroup } from './forms/EditFoodGroup'

export const FoodGroups = (props) => {

    const [foodGroups, setFoodGroups] = useState([])
    const [loading, setLoading] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [foodGroupToDelete, setFoodGroupToDelete] = useState({})
    const [showAddForm, setShowAddForm] = useState(false)
    const [selectedFoodGroup, setSelectedFoodGroup] = useState({})
    const [showEditForm, setShowEditForm] = useState(false)
    const [categories, setCategories] = useState([])

    useEffect(() => {
        handleDestroyForms()
        if (props.parent.id && !loading) {
            fetchFoodGroups(props.parent.id.split('-')[1])
            .then(() => fetchCategories(props.parent.id.split('-')[1]))
        }
    }, [props.parent])

    const fetchCategories = (async (id) => {
        setLoading(true)
        const method = 'GET'
        const result = await getCategories({ method, id });
        if (result) setCategories(result)
        setLoading(false)
    })

    const fetchFoodGroups = (async (id) => {
        setLoading(true)
        setFoodGroups([])
        const method = 'GET'
        const result = await getFoodGroups({ method, id });
        if (result) setFoodGroups(result)
        setLoading(false)
    })
    const deleteFoodGroup = (async () => {
        setLoading(true)
        const method = "DELETE"
        const body = JSON.stringify(foodGroupToDelete)
        const headers = {
            'Content-Type': 'application/json',
        }
        await foodGroupFetch({ method, body, headers });
        fetchFoodGroups(props.parent.id.split('-')[1])
    })

    const handleEdit = (foodGroup) => {
        setShowAddForm(false)
        setSelectedFoodGroup(foodGroup)
        setShowEditForm(true)
    }
    const handleDelete = (foodGroup) => {
        setFoodGroupToDelete(foodGroup)
        setShowDeleteModal(true)
    }
    const handleAddFoodGroup = () => {
        setShowEditForm(false)
        setShowAddForm(true)
    }
    const handleDestroyForms = () => {
        setShowAddForm(false)
        setShowEditForm(false)
        fetchFoodGroups(props.parent.id.split('-')[1]).then(() => fetchCategories(props.parent.id.split('-')[1]))
    }

    return (
        <Container>
            {showDeleteModal ?
                <Row>
                    <Col>
                        <AlertModal message="Are you sure you want to delete?" primaryAction={deleteFoodGroup} show={showDeleteModal} setShow={setShowDeleteModal} element={foodGroupToDelete} />
                    </Col>
                </Row>
                : <div />
            }
            <Row className="title">
                <Col>
                    <h2>{props.parent.name + " > FoodGroups"}</h2>
                </Col>
            </Row>
            {loading ? <div>Loading...</div> :
                <div id="food-groups-table" className="scrollable">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <>
                                {foodGroups.map(foodGroup => (
                                    <tr key={foodGroup.id}>
                                        <td>{foodGroup.id}</td>
                                        <td>{foodGroup.name}</td>
                                        <td><FontAwesomeIcon className="c-black me-2 button" icon={faEdit} onClick={() => handleEdit(foodGroup)} /></td>
                                        <td><FontAwesomeIcon className="c-black me-2 button" icon={faTrash} onClick={() => handleDelete(foodGroup)} /></td>
                                    </tr>
                                ))}
                            </>
                        </tbody>
                    </Table>
                </div>
            }
            {foodGroups.length === 0 ? <Row className="no-label-text"><Col className="text-center font-size-20">No food groups</Col></Row> : <div />}
            <Button className="bg-dark-blue mt-2 mb-2" variant="primary" type="button" onClick={() => handleAddFoodGroup()}>
                Add Food Group
            </Button>
            {showAddForm ? <AddFoodGroup parent={props.parent} destroyForm={handleDestroyForms} categories={categories} /> : <div />}
            {showEditForm ? <EditFoodGroup selectedFoodGroup={selectedFoodGroup} destroyForm={handleDestroyForms} categories={categories} /> : <div />}
        </Container>
    )
}