import React, { useState, useRef } from 'react'
import { Tooltip, Overlay, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'

import './tooltipComponent.css'

export const TooltipComponent = (props) => {

  const [show, setShow] = useState(false);
  const target = useRef(null);

  console.log(show)

  return (
    <>
      <Button ref={target} onClick={() => setShow(!show)} className="tooltip-button ms-2">
        <FontAwesomeIcon icon={faQuestion} />
      </Button>
      <Overlay target={target.current} show={show} placement="right">
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Mobile
          </Tooltip>
        )}
      </Overlay>
    </>
  )
}
